import React from 'react'
import { getKey } from 'utils'
import { StyledDiv } from './Qwerty.style'

export const Qwerty = ({
  xFocusId,
  xFocus,
  yFocus,
  rows,
  caps,
  isFocussed
}) => (
  <StyledDiv>
    {rows.map((items, i) => (
      <Row
        key={getKey()}
        yFocusId={i}
        {...{ items, xFocus, yFocus, caps, isFocussed }}
      />
    ))}
  </StyledDiv>
)

const Row = ({ items, xFocus, yFocus, yFocusId, caps, isFocussed }) => (
  <div>
    {items.map((item, i) => {
      const focussed = yFocusId === yFocus && xFocus === i && isFocussed
      return (
        <div
          key={getKey()}
          className={focussed ? 'focussed' : null}
          style={{
            textTransform: caps && yFocusId < 5 ? 'uppercase' : 'none'
          }}
        >
          {item}
        </div>
      )
    })}
  </div>
)
