import React, { Component } from 'react'
import { connect } from 'react-redux'
import { destroyPlayer, fastForward, rewind, playAndPause } from './actions'
import { notEqual } from 'utils'
import { StyledDiv, StyledProgressBar, StyledButton } from './Controls.style'

let inactivityTimeout

class Controls extends Component {
  state = {
    xFocus: 1,
    visible: true
  }

  componentDidMount() {
    this.resetControlFadeDelay()
  }

  hide = () => {
    const { player: { spooling, playing } } = this.props
    playing && !spooling && this.setState({ visible: false })
  }

  resetControlFadeDelay = () => {
    const { hide } = this
    clearTimeout(inactivityTimeout)
    inactivityTimeout = setTimeout(hide, 5000)
  }

  componentWillUnmount() {
    clearTimeout(inactivityTimeout)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: {
        moveLeft,
        moveRight,
        pressBack,
        pressEnter,
        playPause,
        fastForward,
        rewind
      },
      player: { element: player, playing, progress, spooling }
    } = this.props
    const { xFocus, visible } = this.state
    const { moved, pressedBack, pressedEnter } = this
    if (!!player) {
      notEqual(moveLeft, focus.moveLeft) && xFocus > 0 && moved(xFocus - 1)
      notEqual(moveRight, focus.moveRight) && xFocus < 2 && moved(xFocus + 1)
      notEqual(pressBack, focus.pressBack) && pressedBack(player)
      notEqual(pressEnter, focus.pressEnter) && pressedEnter(player, xFocus, progress, playing, spooling)
      notEqual(playPause, focus.playPause) && pressedEnter(player, 1, progress, playing, spooling)
      notEqual(fastForward, focus.fastForward) && pressedEnter(player, 2, progress, playing, spooling)
      notEqual(rewind, focus.rewind) && pressedEnter(player, 0, progress, playing, spooling)
    }
  }

  pressedBack = player => this.props.destroyPlayer(player)

  pressedEnter = (player, xFocus, progress, playing, spooling) => {
    const { rewind, playAndPause, fastForward } = this.props
    this.setState({ xFocus, visible: true })
    this.resetControlFadeDelay()
    xFocus === 0 && !spooling && rewind(player)
    xFocus === 1 && playAndPause(player, playing, progress)
    xFocus === 2 && !spooling && fastForward(player)
  }

  moved = xFocus => {
    this.setState({ xFocus, visible: true })
    this.resetControlFadeDelay()
  }

  render() {
    const { xFocus, visible } = this.state
    const {
      player: { progress, spooling, playing }
    } = this.props
    return (
      <StyledDiv style={{ opacity: visible ? 1 : 0 }}>
        <StyledProgressBar {...{ progress }} />
        <StyledButton
          focussed={xFocus === 0}
          icon={'rewind'}
        />
        <StyledButton
          focussed={xFocus === 1}
          icon={playing && !spooling ? 'pause' : 'play'}
        />
        <StyledButton
          focussed={xFocus === 2}
          icon={'ff'}
        />
      </StyledDiv>
    )
  }
}

const connected = connect(null, { playAndPause, fastForward, rewind, destroyPlayer })(Controls)
export default connected
