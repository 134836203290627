import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { history } from 'store'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import AgeGate from 'components/AgeGate'
import Keyboard from 'components/Keyboard'
import { StyledDiv } from './SignUp.style'

class Step1 extends Component {
  state = {
    dob: false,
    errors: false
  }

  pressedEnter = key => {
    const { dob, errors } = this.state
    if (!dob) {
      this.setState({
        dob: key.toString(),
        errors: false
      })
    } else if (dob.length < 4) {
      this.setState({
        dob: dob + key.toString(),
        errors: false
      })
    }
  }

  pressedDelete = () => {
    const { dob } = this.state
    this.setState({ dob: dob.slice(0, -1).toString() })
  }

  handleContinue = () => {
    const { dob } = this.state
    const { user } = this.props
    if (dob <= this.eighteenYearsAgo() && !!dob && dob >= this.oneHundredAndTwentyYearsAgo()) {
      !user ? history.push('/sign-up/step2') : history.push('/sign-up/amazon-subscribe')
    } else {
      this.setState({
        errors: { message: 'Sorry - you need to be over 18 to sign up.' }
      })
    }
  }

  eighteenYearsAgo = () =>
    moment()
      .subtract(18, 'years')
      .format('YYYY')

  oneHundredAndTwentyYearsAgo = () =>
    moment()
      .subtract(120, 'years')
      .format('YYYY')

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  render() {
    const { pressedDelete, pressedEnter, handleContinue } = this
    const { dob, errors } = this.state
    const { focus } = this.props
    return (
      <StyledDiv>
        <Keyboard xFocusId={1} {...{ pressedDelete, pressedEnter }} />
        <AgeGate xFocusId={2} {...{ dob, errors, focus, handleContinue }} />
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus, user }) => ({ focus, user })
const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(Step1)
export default connected
