import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { history } from 'store'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { StyledSection, StyledButton } from './SignUp.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class LoginOptions extends Component {
  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, pressEnter, pressBack },
        moveXFocus
      } = this.props
      notEqual(focus.moveLeft, moveLeft) && moveXFocus(-1)
      notEqual(focus.pressBack, pressBack) && history.goBack()
      notEqual(focus.pressEnter, pressEnter) && alert('Error Code 1')
    }
  }

  render() {
    const { handleContinue } = this
    const { focus } = this.props
    const focussed = isFocussed(this.props)
    return (
      <StyledSection>
        <h2>{'Link your Amazon account to you Hopster Account'}</h2>
        <StyledButton focussed={focussed}>{'Link Amazon Account'}</StyledButton>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(LoginOptions)
export default connected
