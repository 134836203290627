import {
  CREATE_PLAYER,
  PLAY_AND_PAUSE,
  PLAYER_READY,
  UPDATE_PROGRESS,
  DESTROY_PLAYER,
  SPOOLING_PLAYER,
  SEEK_PLAYER
  //, ERROR_PLAYER, RESET_DEBUG_PLAYER, DEBUG_PLAYER
} from './actions'

const initialState = false

const reducer = function playerReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PLAYER:
      return false
    case PLAYER_READY:
      return {
        element: action.payload,
        progress: 0,
        spooling: false,
        playing: false
      }
    case PLAY_AND_PAUSE:
      return {
        ...state,
        spooling: false,
        playing: !state.playing
      }
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload
      }
    case SPOOLING_PLAYER:
      return {
        ...state,
        playing: false,
        spooling: action.payload
      }
    case DESTROY_PLAYER:
      return false
    default:
      return state
  }
}

export default reducer
