import React from 'react'
import { StyledDiv } from './Hero.style'

const findObjectById = (hid, shows) => shows.find(show => show['hid'] === hid)

const Hero = ({ xFocusArray, yFocus, carousels, shows }) => {
  const { title, hid, series_hid, banner_url, logo_url, synopsis } = carousels[
    yFocus
  ].collections[xFocusArray[yFocus]]
  const showHid = !!series_hid ? series_hid : hid
  const showTitle = findObjectById(showHid, shows).title
  const width = document.getElementById('root').clientWidth
  return (
    <StyledDiv>
      {!!banner_url && (
        <img
          src={`${banner_url.replace('http:', 'https:')}=s${~~width}`}
          alt={''}
        />
      )}
      <div>
        {!!logo_url && (
          <img src={logo_url.replace('http:', 'https:')} alt={''} />
        )}
        {synopsis && <p>{synopsis}</p>}
      </div>
    </StyledDiv>
  )
}

export default Hero
