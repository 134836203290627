import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import {
  signUpRequest,
  logoutSuccess,
  signUpWithAmazonRequest
} from 'App/auth/actions'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import lwa from 'img/lwa_312x64.png'
import { StyledDiv, StyledLWAImg } from './SignUpForm.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class SignUpForm extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveLeft, moveUp, moveDown, pressEnter, pressBack },
      yFocus,
      moved,
      moveXFocus,
      signUpWithAmazonRequest
    } = this.props
    const { REACT_APP_BUILD_TYPE } = process.env
    const { signUpAttempt } = this
    const maxYFocus = REACT_APP_BUILD_TYPE === 'amazon' ? 3 : 2
    if (isFocussed(this.props)) {
      notEqual(moveLeft, focus.moveLeft) && yFocus < 2 && moveXFocus(-1) // Note - you can't move from the login button to the keboard
      notEqual(moveUp, focus.moveUp) && yFocus !== 0 && moved(-1)
      notEqual(moveDown, focus.moveDown) && yFocus < maxYFocus && moved(1)
      notEqual(pressEnter, focus.pressEnter) && yFocus <= 1 && moveXFocus(-1)
      notEqual(pressEnter, focus.pressEnter) && yFocus === 2 && signUpAttempt() // TODO: check email is correctly formatted?
      notEqual(pressEnter, focus.pressEnter) &&
        yFocus === 3 &&
        REACT_APP_BUILD_TYPE === 'amazon' &&
        window.hasOwnProperty('AmazonLoginPlugin') &&
        window.AmazonLoginPlugin.authorize(
          ['profile'],
          signUpWithAmazonRequest,
          () => console.log('fail to lwa')
        )
      notEqual(pressEnter, focus.pressEnter) &&
        yFocus === 3 &&
        REACT_APP_BUILD_TYPE === 'amazon' &&
        !window.hasOwnProperty('AmazonLoginPlugin') &&
        alert('Amazon Login SDK not found')
      notEqual(pressBack, focus.pressBack) && moveXFocus(-2)
    }
  }

  componentDidMount() {
    const { logoutSuccess } = this.props
    logoutSuccess()
  }

  signUpAttempt = () => {
    const {
      auth: { email, password },
      signUpRequest
    } = this.props
    signUpRequest(email, password)
  }

  render() {
    const {
      auth: { email, password, errors /* requesting, successful */ },
      auth,
      yFocus
    } = this.props
    const { REACT_APP_BUILD_TYPE } = process.env
    const maskedPassword = password.split('').map(() => '*')
    const focussed = isFocussed(this.props)
    return (
      <StyledDiv>
        <h2>{'Sign up using email'}</h2>
        <div className="login-input">
          <div
            className={
              yFocus === 0 && focussed
                ? 'input focussed'
                : errors.length > 0
                ? 'input error'
                : 'input'
            }
          >
            {email === '' ? (
              <div className="login-label">{'EMAIL ADDRESS'}</div>
            ) : (
              email
            )}
          </div>
        </div>
        <div className="login-input">
          <div
            className={
              yFocus === 1 && focussed
                ? 'input focussed'
                : errors.length > 0
                ? 'input error'
                : 'input'
            }
          >
            {password === '' ? (
              <div className="login-label">{'PASSWORD'}</div>
            ) : (
              maskedPassword
            )}
          </div>
        </div>
        <div
          className={
            yFocus === 2 && focussed
              ? 'button login-button focussed'
              : 'button login-button'
          }
        >
          {'Sign up'}
        </div>
        {errors.length > 0 && errors[0].type.response.status === 409 && (
          <p>{'Oops, it seems like a user with that email has already signed up with Hopster. Can you use an alternate email to sign up?'}</p>
        )}
        {REACT_APP_BUILD_TYPE === 'amazon' && (
          <Fragment>
            <hr />
            <h2>{'Or login with Amazon'}</h2>
            <StyledLWAImg
              src={lwa}
              alt={''}
              focussed={yFocus === 3 && focussed}
            />
          </Fragment>
        )}
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ auth, content, focus }) => ({ auth, content, focus })

const connected = connect(
  mapStateToProps,
  {
    signUpRequest,
    signUpWithAmazonRequest,
    logoutSuccess,
    moveXFocus,
    resetXFocus
  }
)(SignUpForm)

export default connected
