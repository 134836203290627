import styled from 'styled-components'

export const StyledDiv = styled.div`
  display: flex;
  position: relative;
`

export const StyledTile = styled.div`
  width: 25vw;
  z-index: 0;
  margin-right: 2.5vw;
  border-radius: 1.2vw;
  box-sizing: border-box;
  border: 1vw solid ${({ focussed }) => (focussed ? '#FFF' : 'transparent')};
  background-size: cover;
  background-repeat: no-repeat;
  img {
    width: 25vw;
  }
`
