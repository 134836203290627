import styled from 'styled-components'
import down from 'img/down.svg'
import up from 'img/up.svg'
import down_focussed from 'img/down_focussed.svg'
import up_focussed from 'img/up_focussed.svg'

export const StyledDiv = styled.div`
  height: 50vh;
  position: relative;
  overflow: hidden;
  padding: 4vh 2.8vw 4vh 4vw;
  width: 82.5vw;
  border-radius: 1vw;
  background-color: rgba(0, 0, 0, 0.2);
`
export const StyledContainer = styled.div`
  transition: all 0.3s ease-out;
  padding: 4vh 2.5vw 10vh 0;
  p,
  ul,
  h4 {
    margin-bottom: 2vh;
    margin-top: 2vh;
  }
  h3 {
    margin-top: 4vh;
  }
`

export const StyledScrollers = styled.div`
  position: absolute;
  top: 4vh;
  right: 0;
  width: 4vw;
  height: 90%;
`

export const StyledScrollButtons = styled.div`
  height: 50%;
  background-size: 200% auto;
  background-position: 60% 0;
  background-repeat: no-repeat;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  background-image: url(${({ focussed }) => (focussed ? down_focussed : down)});
  &:first-of-type {
    background-image: url(${({ focussed }) => (focussed ? up_focussed : up)});
  }
`
