// import Hls from 'hls.js'
import videojs from 'video.js'
import store from 'store'
import { addToLastWatched } from 'App/videos/actions'

export const CREATE_PLAYER = 'CREATE_PLAYER'
export const PLAYER_READY = 'PLAYER_READY'
export const PLAY_AND_PAUSE = 'PLAY_AND_PAUSE'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const DESTROY_PLAYER = 'DESTROY_PLAYER'
export const ADD_TO_LAST_WATCHED = 'ADD_TO_LAST_WATCHED'
export const SPOOLING_PLAYER = 'SPOOLING_PLAYER'
export const SEEK_PLAYER = 'SEEK_PLAYER'

let duration
let i = 0

export function createPlayer(item) {
  return dispatch => {
    dispatch(createPlayerRequest())
    const playerContainer = document.getElementById('player')
    playerContainer.classList.add('display')
    const vid = document.getElementById('video')
    const player = videojs(vid).ready(function() {
      const p = this
      p.controlBar.progressControl.disable()
      p.controlBar.removeChild('volumePanel')
      p.catalog.getVideo(item.brightcove_id, function(error, video) {
        p.catalog.load(video)
        duration = video.duration
        dispatch(createPlayerReceived(p))
        dispatch(playAndPause(p, false, 0))
      })
      p.on(
        'ended',
        () => !!store.getState().player && dispatch(destroyPlayer(this))
      )
      // p.on(
      //   'error',
      //   () => !!store.getState().player && alert('error')
      // )
    })
  }
}

function createPlayerRequest() {
  return {
    type: CREATE_PLAYER
  }
}

export function playAndPause(player, playing, position) {
  return dispatch => {
    dispatch(updateProgress(position))
    player.currentTime((duration / 100) * position)
    playing ? player.pause() : player.play()
    return dispatch(playPauseRequest())
  }
}

function playPauseRequest() {
  return { type: PLAY_AND_PAUSE }
}

const progressInterval = setInterval(progress, 1000)

function progress() {
  if (!!store.getState().player) {
    const progress = (100 / duration) * store.getState().player.element.currentTime()
    progress > store.getState().player.progress && store.getState().player.playing &&
      store.dispatch(updateProgress(progress))
  }
}

const spoolingInterval = setInterval(spooling, 1000)

function spooling() {
  const player = store.getState().player.element
  if (!!player && store.getState().player.spooling) {
    let progress = (100 / duration) * player.currentTime()
    i++
    const p = store.getState().player.spooling === 'forward' ? progress + (i * 2) : progress - (i * 2)
    return p < 0
      ? store.dispatch(playAndPause(player, false, 0))
      : p < 100
        ? store.dispatch(updateProgress(p))
        : store.dispatch(destroyPlayer(player))
  }
}

function createPlayerReceived(payload) {
  return {
    type: PLAYER_READY,
    payload
  }
}

export function destroyPlayer(playerElement) {
  return dispatch => {
    dispatch(removePlayer())
    playerElement.pause()
    playerElement.currentTime(0)
    i = 0
    const playerContainer = document.getElementById('player')
    playerContainer.classList.remove('display')
    document.getElementById('root').lastChild.click() // Force a click to restore keyboard / remote navigation
  }
}

export function fastForward(player) {
  player.pause()
  i = 0
  return dispatch => dispatch(spoolingPlayer('forward'))
}

export function rewind(player) {
  player.pause()
  i = 0
  return dispatch => dispatch(spoolingPlayer('backward'))
}

function spoolingPlayer(direction) {
  return {
    type: SPOOLING_PLAYER,
    payload: direction
  }
}

function updateProgress(payload) {
  return {
    type: UPDATE_PROGRESS,
    payload
  }
}

function removePlayer() {
  return {
    type: DESTROY_PLAYER
  }
}
