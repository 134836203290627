import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetXFocus } from 'App/focus/actions'
import Keyboard from 'components/Keyboard'
import LoginForm from 'components/LoginForm'
import { loginRequest, updateLoginForm } from 'App/auth/actions'
import { StyledDiv } from './Login.style'

class Login extends Component {
  state = {
    yFocus: 0
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(2)
  }

  pressedDelete = () => {
    const { yFocus } = this.state
    const {
      auth: { email, password },
      updateLoginForm
    } = this.props
    yFocus === 0 && updateLoginForm(email.slice(0, -1), 'email')
    yFocus === 1 && updateLoginForm(password.slice(0, -1), 'password')
  }

  pressedEnter = (key, caps, type) => {
    const { yFocus } = this.state
    const {
      auth: { email, password },
      updateLoginForm
    } = this.props
    const char = caps && typeof key === 'string' ? key.toUpperCase() : key
    yFocus === 0 && updateLoginForm(email + char, 'email')
    yFocus === 1 && updateLoginForm(password + char, 'password')
  }

  moved = step =>
    this.setState(prevState => ({ yFocus: prevState.yFocus + step }))

  render() {
    const { pressedDelete, pressedEnter, moved } = this
    const { yFocus } = this.state
    const { handleMoveXFocus } = this.props
    return (
      <StyledDiv>
        <Keyboard
          xFocusId={1}
          {...{ pressedDelete, pressedEnter, handleMoveXFocus }}
        />
        <LoginForm xFocusId={2} {...{ moved, yFocus, handleMoveXFocus }} />
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const connected = connect(
  mapStateToProps,
  { resetXFocus, updateLoginForm }
)(Login)
export default connected
