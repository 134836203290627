import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { logoutRequest } from 'App/auth/actions'
import { history } from 'store'
import {
  notEqual,
  userHasSubscription,
  userHasActiveSubscription,
  baseRequest,
  getAuthHeaders
} from 'utils'
import { StyledSection, StyledButton, StyledDiv } from './Account.style'
import pwa from 'img/pwa.png'

const isFocussed = ({ xFocus, xFocusId }) => xFocus === xFocusId

class Account extends Component {
  state = {
    yFocus: 0
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, moveUp, moveDown, pressEnter, pressBack },
        moveXFocus,
        logoutRequest,
        user
      } = this.props
      const { REACT_APP_BUILD_TYPE } = process.env
      const { yFocus } = this.state
      const { pressedEnter } = this
      notEqual(focus.moveLeft, moveLeft) && moveXFocus(-1)
      notEqual(focus.pressBack, pressBack) && history.goBack()
      notEqual(focus.pressEnter, pressEnter) &&
        yFocus === 0 &&
        REACT_APP_BUILD_TYPE === 'amazon' &&
        (!userHasSubscription(user) || !userHasActiveSubscription(user)) &&
        history.push('/sign-up/amazon-subscribe')
      notEqual(focus.pressEnter, pressEnter) &&
        yFocus === 0 &&
        (userHasSubscription(user) && userHasActiveSubscription(user)) &&
          logoutRequest()
      notEqual(focus.pressEnter, pressEnter) &&
        yFocus === 1 &&
        !!user && userHasSubscription(user) && userHasActiveSubscription(user) &&
          logoutRequest()
      notEqual(focus.pressEnter, pressEnter) && yFocus === 1 && logoutRequest()
      notEqual(focus.moveUp, moveUp) &&
        yFocus !== 0 &&
        this.setState({ yFocus: 0 })
      notEqual(focus.moveDown, moveDown) &&
        yFocus === 0 &&
        REACT_APP_BUILD_TYPE === 'amazon' &&
        (!userHasSubscription(user) || !userHasActiveSubscription(user)) &&
        this.setState({ yFocus: 1 })
    }
  }

  formatDate = date => <b>{moment(date).format('dddd, MMMM Do YYYY')}</b>

  render() {
    const { user } = this.props
    const { yFocus } = this.state
    const { formatDate } = this
    const { REACT_APP_BUILD_TYPE } = process.env
    const focussed = isFocussed(this.props)
    const logoutFocus =
      REACT_APP_BUILD_TYPE === 'amazon' &&
      !(userHasSubscription(user) && userHasActiveSubscription(user))
        ? 1
        : 0
    return (
      <StyledSection>
        <h2>{'Your Account'}</h2>
        <h3>{'Subscription'}</h3>
        {!user ? (
          <p>{'You are not logged in.'}</p>
        ) : userHasSubscription(user) && userHasActiveSubscription(user) ? (
          <p>
            {'Your subsciption is valid until:'} {formatDate(user.sub_expiry)}
          </p>
        ) : (
          <Fragment>
            <p>
              {'Your subsciption expired on:'} {formatDate(user.sub_expiry)}
            </p>

            {REACT_APP_BUILD_TYPE === 'amazon' && (
              <Fragment>
                <h3>{'Start your 7 day free trial via Amazon Payments:'}</h3>
                <StyledButton focussed={focussed && yFocus === 0}>
                  {'Start Free Trial'}
                </StyledButton>
              </Fragment>
            )}
          </Fragment>
        )}
        {!!user && (
          <StyledButton focussed={focussed && yFocus === logoutFocus}>
            {'Log out'}
          </StyledButton>
        )}
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus, user }) => ({ focus, user })

const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus, logoutRequest }
)(Account)
export default connected
