import React, { Component } from 'react'
import { connect } from 'react-redux'
import keydown from 'react-keydown'
import { usedKey, moveXFocus, resetXFocus } from 'App/focus/actions'
import { createPlayer } from 'components/Player/actions'
import Carousel from 'components/Carousel'
import { notEqual, userHasSubscription, userHasActiveSubscription } from 'utils'
import { fetchShow } from './actions'
import { history } from 'store'
import { StyledSection } from './Show.style'

const isFocussed = ({ xFocus, xFocusId, player }) =>
  xFocus === xFocusId && !player

class Show extends Component {
  state = {
    yFocus: 0,
    xFocusArray: [0],
    scrollHeight: [false],
    episodes: [],
    episodesReady: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, moveRight, pressEnter, pressBack },
        videos: { freeVideos },
        player,
        handleMoveXFocus
      } = this.props
      const { yFocus, xFocusArray, episodes } = this.state
      const { handleMove, getRouteForShow } = this
      notEqual(moveLeft, focus.moveLeft) && handleMove(-1)
      notEqual(moveRight, focus.moveRight) &&
        xFocusArray[yFocus] < episodes.length - 1 &&
        handleMove(1)
      notEqual(pressEnter, focus.pressEnter) &&
        getRouteForShow(episodes, freeVideos)
      notEqual(pressBack, focus.pressBack) && !player && history.goBack()
    }
  }

  handleMove = step => {
    const { yFocus, xFocusArray } = this.state
    const { moveXFocus } = this.props
    const focus = xFocusArray[yFocus]
    return focus === 0 && step < 0
      ? moveXFocus(-1)
      : xFocusArray.splice(yFocus, 1, focus + step)
  }

  getRouteForShow = (carousel, freeVideos) => {
    const { user, createPlayer } = this.props
    const { xFocusArray, yFocus } = this.state
    const item = carousel[xFocusArray[yFocus]]
    return userHasSubscription(user) ||
      this.findObjectById(item.hid, freeVideos)
      ? userHasActiveSubscription(user) ||
        this.findObjectById(item.hid, freeVideos)
        ? !!item.brightcove_id
          ? createPlayer(item)
          : alert('no brightcove id') // TODO: handle as part of Hopster-9 player error ticket?
        : history.push(`/account`)
      : history.push(`/`)
  }

  measureHeight = (height, position) => {
    const { scrollHeight } = this.state
    scrollHeight[position] = height
    this.setState({ scrollHeight })
  }

  componentDidMount() {
    const {
      videos: { shows, freeVideos },
      match: {
        params: { hid }
      },
      user,
      fetchShow,
      resetXFocus
    } = this.props
    resetXFocus(1)
    const token =
      userHasSubscription(user) && userHasActiveSubscription(user)
        ? localStorage.getItem('token')
        : false
    const item = this.findObjectById(hid, shows)
    fetchShow(item.id, item.content_type, token)
      .then(response => {
        const freeEpisodes = []
        const nonFreeEpisodes = []
        const newEpisodes = []
        const nonNewEpisodes = []
        if (!!response.payload && response.payload.length > 0) {
          response.payload.map(item =>
            this.findObjectById(item.hid, freeVideos)
              ? freeEpisodes.push(item)
              : nonFreeEpisodes.push(item)
          )
        }
        nonFreeEpisodes.sort((a, b) =>
          (parseInt(a.series) > parseInt(b.series))
            ? 1
            : (parseInt(a.series) === parseInt(b.series))
              ? ((parseInt(a.episode) > parseInt(b.episode))
                ? 1
                : -1)
              : -1 )
        nonFreeEpisodes.map(e => e['new'] ? newEpisodes.push(e) : nonNewEpisodes.push(e))
        this.setState({
          episodes: freeEpisodes.concat(newEpisodes).concat(nonNewEpisodes),
          episodesReady: true
        })
      })
      .catch(() => history.push('/404'))
  }

  findObjectById = (hid, objects) => objects.find(obj => obj.hid === hid)

  render() {
    const {
      focus: { xFocus },
      videos: { shows },
      match: {
        params: { hid }
      },
      xFocusId
    } = this.props
    const { measureHeight, handleMoveXFocus } = this
    const {
      xFocusArray,
      yFocus,
      scrollHeight,
      episodes,
      episodesReady
    } = this.state
    const title = !!this.findObjectById(hid, shows)
      ? this.findObjectById(hid, shows).title
      : false

    return (
      episodesReady &&
      episodes.length > 0 && (
        <StyledSection>
          <Carousel
            carousel={episodes}
            yFocusId={0}
            focusPosition={xFocusArray[yFocus]}
            tileType={'episode'}
            {...{
              title,
              xFocus,
              xFocusId,
              yFocus,
              handleMoveXFocus,
              measureHeight,
              scrollHeight
            }}
          />
        </StyledSection>
      )
    )
  }
}

const mapStateToProps = ({ show, videos, focus, user, player }) => ({
  show,
  videos,
  focus,
  user,
  player
})

const connected = connect(
  mapStateToProps,
  { fetchShow, usedKey, moveXFocus, resetXFocus, createPlayer }
)(Show)
export default keydown(connected)
