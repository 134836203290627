import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetXFocus } from 'App/focus/actions'
import Keyboard from 'components/Keyboard'
import SearchResults from 'components/SearchResults'
import { StyledDiv } from './Search.style'

class Search extends Component {
  state = {
    searchTerm: '',
    searchResults: []
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(2)
  }

  pressedDelete = () => {
    const searchTerm = this.state.searchTerm.slice(0, -1)
    this.setState({ searchTerm })
    this.searchShows(searchTerm)
  }

  pressedEnter = (key, caps) => {
    const char = caps ? key.toUpperCase() : key
    const searchTerm = this.state.searchTerm + char
    this.setState({ searchTerm })
    this.searchShows(searchTerm)
  }

  searchShows = searchTerm => {
    const {
      videos: { shows }
    } = this.props
    const searchResults = []
    searchTerm !== '' &&
      shows.filter(show => {
        if (
          show.title
            .toLowerCase()
            .replace("'", '')
            .slice(0, searchTerm.length)
            .indexOf(searchTerm.toLowerCase()) !== -1
        ) {
          searchResults.push(show)
        }
      })
    this.setState({ searchResults: searchResults.slice(0, 7) })
  }

  render() {
    const { pressedDelete, pressedEnter } = this
    const { searchTerm, searchResults } = this.state
    const { handleMoveXFocus } = this.props
    return (
      <StyledDiv>
        <Keyboard
          xFocusId={1}
          {...{ pressedDelete, pressedEnter, handleMoveXFocus }}
        />
        <SearchResults
          xFocusId={2}
          {...{ searchTerm, searchResults, handleMoveXFocus }}
        />
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ videos }) => ({ videos })
const connected = connect(
  mapStateToProps,
  { resetXFocus }
)(Search)
export default connected
