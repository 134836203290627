import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 25vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  margin-left: 7.5vw;
  display: flex;
`

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    width: 10%;
  }
  > div:first-of-type {
    width: 90%;
  }
`
