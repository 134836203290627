import { take, fork, call, put, select } from 'redux-saga/effects'
import { history } from 'store'
import { receiveUser, removeUser } from 'App/user/actions'
import { clearLastWatched } from 'App/videos/actions'
import { handleApiErrors } from './errors'
import {
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  LOGIN_FROM_TOKEN_REQUEST,
  LOGOUT_REQUEST,
  SET_LWA_TOKEN,
  GET_LWA_TOKEN,
  UPDATE_USER,
  loginSuccess,
  loginError,
  logoutSuccess
} from './actions'
import { baseRequest, getAuthHeaders } from 'utils'

const LOGIN_SUCCESS_ROUTE = '/home'
const TOKEN_KEY = 'token'

/**
 * @function _getTokenObjectFromStorage
 * Convenience method to return the parsed token data from local storage
 */
function _getTokenObjectFromStorage() {
  const tokenInStorage = localStorage.getItem(TOKEN_KEY)
  if (!tokenInStorage) {
    return null
  }
  return tokenInStorage
}

/**
 * @function _loginApi
 * Send the email/password to the API to attempt login
 * @param {String} email
 * @param {String} password
 */
function _loginApi(email, password) {
  return baseRequest
    .post('accounts/login', { email, password })
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 *
 *
 *
 */

function _bypassApiLogin(buildType) {
  let deviceID = Math.random().toString(36)
  const { REACT_APP_BUILD_TYPE } = process.env
  if (REACT_APP_BUILD_TYPE === 'virgin' && !!window.oipfObjectFactory) {
    const configurationObj = window.oipfObjectFactory.createConfigurationObject()
    if (
      !!configurationObj &&
      !!configurationObj.localSystem &&
      !!configurationObj.localSystem.serialNumber
    ) {
      deviceID = configurationObj.localSystem.serialNumber
    }
  }
  return baseRequest
    .post('partners/devices/login', {
      country: '',
      device_id: deviceID,
      partner:
        REACT_APP_BUILD_TYPE === 'virgin'
          ? 'VIRGIN_MEDIA'
          : buildType.toUpperCase(),
      source: '',
      secret_key: 'jj94iwxa9h1qs2imvllx6hzrqaydm3bp'
    })
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 * @function _signUpApi
 * Send the email/password to the API to attempt sign up
 * @param {String} email
 * @param {String} password
 */
function _signUpApi(email, password) {
  return baseRequest
    .put('accounts/register', { email, password, weekly_updates: false })
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 * @function _loginWithAmazonApi
 * Send the Amazon accessToken to attempt to login
 * @param {String} accessToken
 */
function _loginWithAmazonApi(accessToken) {
  return baseRequest
    .post('accounts/amazon/login', { access_token: accessToken })
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 * @function _userApi
 * Send the email/password to the API to attempt login
 * @param {String} token
 */
function _userApi(token) {
  return baseRequest
    .get('accounts/info', getAuthHeaders(token))
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 * @generator _login
 * Control flow for the login process
 */
function* _login() {
  try {
    // Get our email & password from state
    const { email, password } = yield select(({ auth }) => auth)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginApi returns a response
    const data = yield call(_loginApi, email, password)
    const token = data.auth_token
    delete data.auth_token
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(receiveUser(data))
    yield put(loginSuccess(token))
    history.push(LOGIN_SUCCESS_ROUTE)
  } catch (error) {
    // Dispatch the login error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(loginError(error))
  }
}

/**
 * @generator _loginWithAmazon
 * Control flow for the login process
 */
function* _loginWithAmazon() {
  try {
    // Get our email & password from state
    const { accessToken } = yield select(({ auth }) => auth)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginWithAmazonApi returns a response
    const data = yield call(_loginWithAmazonApi, accessToken)
    const token = data.auth_token
    delete data.auth_token
    data.amazon_user = true
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(receiveUser(data))
    yield put(loginSuccess(token))
    history.push(LOGIN_SUCCESS_ROUTE)
  } catch (error) {
    // Dispatch the login error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(loginError(error))
  }
}

/**
 * @generator _loginWithAmazon
 * Control flow for the login process
 */
function* _signUpWithAmazon() {
  try {
    // Get our email & password from state
    const { accessToken } = yield select(({ auth }) => auth)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginWithAmazonApi returns a response
    const data = yield call(_loginWithAmazonApi, accessToken)
    const token = data.auth_token
    delete data.auth_token
    data.amazon_user = true
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(receiveUser(data))
    yield put(loginSuccess(token))
    history.push('/sign-up/amazon-subscribe')
  } catch (error) {
    // Dispatch the login error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(loginError(error))
  }
}

/**
 * @generator _updateUser
 * Control flow for the updateUser process after e.g. successful amazon trial sub
 */
function* _updateUser() {
  // Try to get the token from local storage
  let token = _getTokenObjectFromStorage()
  // If there is a token present then validate the token
  // If there is no token or the token is invalid then we should logout the user
  if (!token) {
    // TODO: something went really wrong if this happened
  }
  const data = yield call(_userApi, token)
  yield put(receiveUser(data))
  // yield put(loginSuccess(token))
  history.push('/home')
}

/**
 * @generator _loginFromToken
 * Control flow for the login from token process
 */
function* _loginFromToken() {
  // Try to get the token from local storage
  let token = _getTokenObjectFromStorage()
  const { REACT_APP_BUILD_TYPE } = process.env
  // If there is a token present then validate the token
  // If there is no token or the token is invalid then we should logout the user
  if (!token) {
    // Dispatch the logout success action
    yield put(logoutSuccess())
    if (
      REACT_APP_BUILD_TYPE === 'vodafonero' ||
      REACT_APP_BUILD_TYPE === 'ooredoo' ||
      REACT_APP_BUILD_TYPE === 'virgin'
    ) {
      const { auth_token } = yield _bypassApiLogin(REACT_APP_BUILD_TYPE)
      token = auth_token
      localStorage.setItem('token', token)
    } else {
      return undefined
    }
  }
  try {
    const data = yield call(_userApi, token)
    yield put(receiveUser(data))
    yield put(loginSuccess(token))
  } catch (e) {
    // TODO - check the error code is code is a 401
    yield put(logoutSuccess())
    if (
      REACT_APP_BUILD_TYPE === 'vodafonero' ||
      REACT_APP_BUILD_TYPE === 'ooredoo' ||
      REACT_APP_BUILD_TYPE === 'virgin'
    ) {
      const { auth_token } = yield _bypassApiLogin(REACT_APP_BUILD_TYPE)
      token = auth_token
      localStorage.setItem('token', token)
      const data = yield call(_userApi, token)
      yield put(receiveUser(data))
      yield put(loginSuccess(token))
    } else {
      // Amazon & Gibtelcom build:

    }
  }

}

/**
 * @function _logout
 * Logout the current user
 * TODO: Feels like this should hit the API and destroy the current session
 */
function* _logout() {
  // Remove the token to logout the user in the browser
  localStorage.removeItem(TOKEN_KEY)
  // Dispatch the logout success action
  // All required Redux reducers will respond to this action
  // We dispatch one Login specific action and then reducers act accordingly
  yield put(clearLastWatched())
  yield put(removeUser())
  yield put(logoutSuccess())
  history.push('/')
}

/**
 * @generator _login
 * Control flow for the login process
 */
function* _signUp() {
  try {
    // Get our email & password from state
    const { email, password } = yield select(({ auth }) => auth)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginApi returns a response
    const data = yield call(_signUpApi, email, password)
    const token = data.auth_token
    delete data.auth_token
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(receiveUser(data))
    yield put(loginSuccess(token))
    history.push('/sign-up/amazon-subscribe') // TODO: currently the only signup option is via amazon but this will need logic extension if it needs to support any other build types
  } catch (error) {
    // Dispatch the login error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(loginError(error))
  }
}

/**
 * @generator loginWatcher
 * Watches for login actions and acts accordingly
 * NOTE: This method should be thin, it does nothing other than catch actions
 * and then either fork (non-blocking) or call (blocking) to another generator function
 */
export default function* loginWatcher() {
  while (true) {
    // Take all the actions you want to handle in this watcher
    const { type } = yield take([
      LOGIN_REQUEST,
      LOGIN_FROM_TOKEN_REQUEST,
      LOGOUT_REQUEST,
      SET_LWA_TOKEN,
      GET_LWA_TOKEN,
      SIGNUP_REQUEST,
      UPDATE_USER
    ])

    // Switch on the action type and fork/call other generators
    // Use *fork* for non-blocking and use *call* is blocking
    switch (type) {
      case LOGIN_REQUEST: {
        // Call to login generator
        // NOTE: We do not pass email / password to this function
        // When using saga it is a better practice to select your data from state and then use this
        yield fork(_login)
        break
      }
      case SET_LWA_TOKEN: {
        // Call to loginWithAmazon generator
        yield fork(_loginWithAmazon)
        break
      }
      case GET_LWA_TOKEN: {
        // Call to loginWithAmazon generator
        yield fork(_signUpWithAmazon)
        break
      }
      case LOGIN_FROM_TOKEN_REQUEST: {
        // Call to login from token generator
        yield fork(_loginFromToken)
        break
      }
      case UPDATE_USER: {
        yield fork(_updateUser)
        break
      }
      case LOGOUT_REQUEST: {
        // Call to logout generator
        yield fork(_logout)
        break
      }
      case SIGNUP_REQUEST: {
        // Call to loginWithAmazon generator
        yield fork(_signUp)
        break
      }
      default:
        // Do nothing
        // This prevents the linter from choking
        break
    }
  }
}
