import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import LoginWithAmazon from './LoginWithAmazon'
import SubscribeWithAmazon from './SubscribeWithAmazon'

class SignUp extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={'/sign-up/step1'}
          render={props => <Step1 {...props} />}
        />
        <Route
          exact
          path={'/sign-up/step2'}
          render={props => <Step2 xFocusId={1} {...props} />}
        />
        <Route
          exact
          path={'/sign-up/step3'}
          render={props => <Step3 {...props} />}
        />
        <Route
          exact
          path={'/sign-up/step4'}
          render={props => <Step4 {...props} />}
        />
        <Route
          exact
          path={'/sign-up/login-with-amazon'}
          render={props => <LoginWithAmazon {...props} />}
        />
        <Route
          exact
          path={'/sign-up/amazon-subscribe'}
          render={props => <SubscribeWithAmazon xFocusId={1} {...props} />}
        />
      </Switch>
    )
  }
}

export default SignUp
