import {
  REQUEST_FREE_VIDEOS,
  RECEIVE_FREE_VIDEOS,
  REQUEST_CAROUSEL_VIDEOS,
  RECEIVE_CAROUSEL_VIDEOS,
  ERROR_VIDEOS,
  ADD_TO_LAST_WATCHED,
  CLEAR_LAST_WATCHED
} from './actions'

const initialState = {
  freeVideos: [],
  freeVideosReady: false,
  shows: [],
  carousels: [],
  carouselsReady: false,
  lastWatched: {
    name: 'Last Watched',
    collections: []
  },
  errors: false
}

const videosReducer = function videosReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FREE_VIDEOS:
      return {
        ...state,
        freeVideos: [],
        freeVideosReady: false,
        carousels: [],
        carouselsReady: false,
        errors: false
      }
    case RECEIVE_FREE_VIDEOS:
      return {
        ...state,
        freeVideos: action.payload,
        freeVideosReady: true
      }

    case REQUEST_CAROUSEL_VIDEOS:
      return {
        ...state,
        carousels: [],
        carouselsReady: false
      }
    case RECEIVE_CAROUSEL_VIDEOS:
      return {
        ...state,
        carousels: action.payload.carousels,
        shows: action.payload.shows,
        carouselsReady: true
      }
    // case ADD_TO_LAST_WATCHED:
    //   const lastWatched = state.lastWatched
    //   if (
    //     !lastWatched.collections.some(item => item.series_hid === action.payload.series_hid)
    //   ) {
    //     lastWatched.collections.push(action.payload[0])
    //   }
    //   return {
    //     ...state,
    //     lastWatched
    //   }
    case CLEAR_LAST_WATCHED:
      return {
        ...state,
        lastWatched: {
          name: 'Last Watched',
          collections: []
        }
      }
    case ERROR_VIDEOS:
      return {
        ...state,
        freeVideos: [],
        freeVideosReady: false,
        carousels: [],
        carouselsReady: false,
        errors: action.payload
      }
    default:
      return state
  }
}

export default videosReducer
