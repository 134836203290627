import styled from 'styled-components'
import hopster from 'img/hopster.svg'
import h from 'img/h.svg'
import account from 'img/icons/account.svg'
import home from 'img/icons/home.svg'
import help from 'img/icons/help.svg'
import search from 'img/icons/search.svg'
import { palette } from 'style'

export const StyledDiv = styled.div`
  position: fixed;
  left: 0;
  padding: 20vh 0 0;
  width: 7.5vw;
  overflow: hidden;
  background-color: ${palette.orange};
  height: 100%;
  z-index: 101;
  transition: 0.25s ease-in-out;

  .menu-item {
    box-sizing: border-box;
    display: block;
    padding: 1.67vw 2.5vw;
    height: 5.73vw;
    color: #fff;
    font-size: 1.77vw;
    font-weight: 400;
    text-align: left;
    border: 1px solid transparent;
    &__text {
      opacity: 0;
      position: absolute;
      vertical-align: top;
      margin: 0.3vw 0 0 1.56vw;
      transition: 0.1s ease-in-out;
      font-family: 'Gotham Book', sans-serif;
    }
    &.current {
      color: #000;
      .icon {
        background-color: #fff; /* TODO: need to think about this - this needs to be a white circle so will need to refactor :/ */
      }
    }
  }

  &.open {
    width: 22.8vw;
    transition: 0.25s ease-in-out;
    .logo {
      background-size: 8.8vw auto;
      background-image: url(${hopster});
    }
    .menu-item {
      &.focussed {
        background-color: #fff;
        color: rgba(218, 62, 118, 1);
        .icon {
          background-color: rgba(218, 62, 118, 1);
        }
      }
      &.current:not(.focussed) {
        color: #fff;
        background-color: rgba(218, 62, 118, 0.8);
        .icon {
          background-color: #fff;
        }
      }
    }
    .menu-item__text {
      opacity: 1;
      transition: 0.5s ease-in-out;
    }
  }

  .logo {
    position: fixed;
    left: 2.5vw;
    width: 8.8vw;
    height: 8.8vw;
    top: 5vh;
    background-image: url(${h});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 2.6vw auto;
    transition: 0.25s ease-in-out;
  }

  .bottom-menu {
    position: absolute;
    right: 0;
    bottom: 2.5vw;
    left: 0;
  }

  .icon {
    display: inline-block;
    width: 2.4vw;
    height: 2.4vw;
    background-color: #fff;
    background-size: 100%;
    background-repeat: no-repeat;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    &.home {
      mask-image: url(${home});
    }
    &.help {
      mask-image: url(${help});
    }
    &.search {
      mask-image: url(${search});
    }
    &.account {
      mask-image: url(${account});
    }
  }
`

export const StyledMask = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
`
