import styled from 'styled-components'

export const StyledDiv = styled.div`
  min-width: 200px;
  margin-right: 20px;
  position: relative;
  > div {
    position: absolute;
    z-index: 0;
    margin: 20px 0 0 20px;
    width: 160px;
    height: 160px;
    border-radius: 12px;
    &.focussed {
      background-color: #fff;
      &.circle {
        width: 170px;
        height: 170px;
        margin: 15px;
        border-radius: 50%;
      }
      &.tilted {
        transform: rotate(45deg);
        width: 150px;
        height: 150px;
        margin: 25px;
      }
    }
  }
  img {
    position: relative;
    z-index: 1;
  }
`
