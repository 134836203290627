import styled from 'styled-components'
import { palette } from 'style'

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 67.5vw;
  height: 100vh;

  h2 {
    padding-top: 4vh;
    &:first-of-type {
      padding-top: 8vh;
      margin-bottom: 8vh !important;
    }
  }

  .login-input {
    box-sizing: border-box;
    height: 7vw;
    position: relative;
    margin-bottom: 2vh;
  }

  .login-label {
    font-size: 1.6vw;
    opacity: 0.6;
    font-family: 'Gotham Book', sans-serif;
  }

  .input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: ${palette.black};
    background-color: ${palette.white};
    border: 4px solid transparent;
    border-radius: 0.4vw;
    margin: 0 auto;
    padding: 0 2vw;
    font-size: 1.6vw;
    width: 30vw;
    height: 8vh;
    &.focussed {
      border: 4px solid ${palette.orange};
    }
    &.error {
      border: 4px solid ${palette.errorRed};
    }
  }

  hr {
    margin-top: 8vh;
    width: 30vw;
    border: none;
    background-color: #fff;
    color: #fff;
    height: 4px;
  }

  span.error {
    opacity: 0;
    &.show {
      opacity: 1;
      position: absolute;
      left: 69.67vw;
      top: 0;
      width: 19.11458vw;
      padding: 0.78125vw;
      font-size: 1.40625vw;
      color: white;
      text-align: left;
      letter-spacing: -0.01vw;
      pointer-events: none;
      background-color: #d94c4a;
      border-radius: 0.52083vw;
      transition: opacity 0.2s ease-out;
      box-sizing: border-box;
    }
  }

  .login-button {
    width: 20vw;
    height: 5vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    font-weight: bold;
    margin-top: 2vh;
    border: 4px solid white;
    border-radius: 0.5vw;
    transition: 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    font-family: 'Gotham Book', sans-serif;
    &.focussed {
      background-color: ${palette.orange};
      border-color: ${palette.orange};
    }
  }

  > p {
    color: #f00;
    margin-top: 8vh;
    font-family: 'Gotham Book', sans-serif;
  }
`

export const StyledLWAImg = styled.img`
  border: 4px solid ${({ focussed }) => (focussed ? '#FFF' : 'transparent')};
  width: 20vw;
`
