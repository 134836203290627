import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { notEqual, getAuthHeaders } from 'utils'
import { history } from 'store'
import { baseRequest } from 'utils'
import { updateUser } from 'App/auth/actions'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { StyledSection, StyledButton } from './SignUp.style'
import pwa from 'img/pwa.png'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

let client_id = '',
  init = false,
  subDone = false,
  IAP,
  IapRequests = {},
  IapAddRequest = function(reqId, options) {
    IapRequests[reqId] = options
  },
  IapGetRequest = reqId => IapRequests[reqId] || {},
  iapLoadError = () => {
    console.log('Amazon In-App-Purchasing V2 only works on the device')
  },
  Amazon

class SubscribeWithAmazon extends Component {
  state = {
    yFocus: 0,
    subSent: false
  }

  componentDidMount() {
    const { resetXFocus, updateUser } = this.props
    resetXFocus(1)
    IAP = window.AmazonIapV2
    if (!!IAP) {
    IAP.addListener('getUserDataResponse', function(evt) {
      console.log('getUserDataResponse', evt)
      // Always store user in session
      // Session.set('amazon_user', evt.response.amazonUserData);
    })
    IAP.addListener('getProductDataResponse', function(resp) {
      console.log('getProductDataResponse', resp)
    })
    IAP.addListener('purchaseResponse', function(evt) {
      console.log('purchaseResponse', evt)
      // TODO: check success or error and fire relevant callback and complete always
      const resp = evt.response,
        options = IapGetRequest(resp.requestId)
      // options && options.complete && options.complete(evt);

      if (evt.response.status === 'SUCCESSFUL' && !subDone) {
        subDone = true
        const token = localStorage.getItem('token')
        baseRequest
          .post('subscriptions/subscribe/amazon', {
            amazon_user_id: resp.amazonUserData.userId, // 'l3HL7XppEMhrOGDnur9',
            purchase_receipt_id: resp.purchaseReceipt.receiptId //'q1YqVbJSyjH28DGPKChw9c0o8nd3ySststQtzSkrzM8tCk43K6z0d_HOTcwwN8vxCrVV0lEqBmopKdMryEksyc9NTclM1MvILyguSS1KLCjQM8zNzyvJAKoqUbIy0lFKAao1NDU0MDczszAzNjYxUqoFAA',
          },
          getAuthHeaders(token)
        )
          .then(data => {
            IAP.notifyFulfillment(
              function(operationResponse) {
                updateUser()
              },
              function(errorResponse) {
                // Handle error response
                console.log(errorResponse)
              },
              [
                {
                  receiptId: resp.purchaseReceipt.receiptId,
                  fulfillmentResult: 'FULFILLED'
                }
              ]
            )

          })
          .catch(data => {
            alert(data)
            console.log('subscriptions/subscribe/amazon ERROR', data)
            IAP.notifyFulfillment(
              function(operationResponse) {
                // Handle operation response
                console.debug(operationResponse)
              },
              function(errorResponse) {
                // Handle error response
                console.debug(errorResponse)
              },
              [
                {
                  receiptId: resp.purchaseReceipt.receiptId,
                  fulfillmentResult: 'FAILED'
                }
              ]
            )
            if (typeof options.error === 'function') {
              options.error(data)
            }
          })
          .finally(data => {
            if (typeof options.complete === 'function') {
              options.complete(data)
            }
          })
      } else {
        if (typeof options.error === 'function') {
          options.error(resp)
        }
        if (typeof options.complete === 'function') {
          options.complete(resp)
        }
      }
    })

    IAP.addListener('getPurchaseUpdatesResponse', function(evt) {
      console.log('getPurchaseUpdatesResponse', evt)
      // TODO: handle missed transactions
    })

    // store user data first thing
    IAP.getUserData()
    // then handle unfulfilled transactions
    IAP.getPurchaseUpdates(
      function(opResp) {
        console.log('IAP.getPurchaseUpdates:', opResp)
        // IapAddRequest(opResp.requestId, options);
      },
      function(opRespErr) {
        // TODO: handle errors
        console.log('IAP.purchase Error:', opRespErr)
      },
      [
        {
          reset: false
        }
      ]
    )

    Amazon = {
      purchase: function(options) {
        console.debug('Amazon IAP purchase')
        // TODO: check if user logged in before purchase
        IAP.purchase(
          function(opResp) {
            console.log('IAP.purchase:', opResp)
            IapAddRequest(opResp.requestId, options)
          },
          function(opRespErr) {
            // TODO: handle errors
            console.log('IAP.purchase Error:', opRespErr)
            history.push('/404')
          },
          [
            {
              sku: 'tv.platomedia.hopsterapp.1month.7daytrial'
            }
          ]
        )
      },
      IAP: function(options) {
        // make sure options is an object
        options = options || {}
      }
    }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, pressEnter, pressBack },
        moveXFocus
      } = this.props
      const { yFocus } = this.state
      notEqual(focus.moveLeft, moveLeft) && moveXFocus(-1)
      notEqual(focus.pressBack, pressBack) && moveXFocus(-1)
      notEqual(focus.pressEnter, pressEnter) &&
        window.hasOwnProperty('AmazonIapV2') &&
        Amazon.purchase({
          success: function () {

          },
          error: function(error){
            // TODO: what shall we do if there is an error? Some type of further help page?
          }
        })
      notEqual(focus.pressEnter, pressEnter) &&
        !window.hasOwnProperty('AmazonIapV2') &&
        alert('Amazon IAP SDK not found')
    }
  }

  render() {
    const { handleContinue } = this
    const { focus } = this.props
    const focussed = isFocussed(this.props)
    return (
      <StyledSection>
        <h3>{'You\'re just a few clicks away from enjoying Hopster\'s great learning shows'}</h3>
        <StyledButton focussed={focussed}>{'Continue'}</StyledButton>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus, updateUser }
)(SubscribeWithAmazon)
export default connected
