import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { history } from 'store'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import ScrollableContainer from 'components/ScrollableContainer'
import Button from 'components/Button'
import { palette } from 'style'
import { Copy } from 'components/PrivacyPolicy/content'
import { StyledDiv, StyledAcceptanceC2A } from './Step2.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class Step2 extends Component {
  state = {
    yFocus: 2,
    accepted: false
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    if (isFocussed(this.props)) {
      const {
        focus: { xFocus, moveLeft, moveRight, moveUp, moveDown, pressEnter },
        moveXFocus
      } = this.props
      const { yFocus, accepted } = this.state
      notEqual(moveLeft, focus.moveLeft) && moveXFocus(-1)
      notEqual(moveRight, focus.moveRight) && focus.xFocus < 2 && moveXFocus(1)
      if (focus.xFocus === 1) {
        notEqual(moveUp, focus.moveUp) &&
          yFocus !== 0 &&
          this.setState(prevState => ({ yFocus: prevState.yFocus - 1 }))
        notEqual(moveDown, focus.moveDown) &&
          yFocus < 3 &&
          this.setState(prevState => ({ yFocus: prevState.yFocus + 1 }))
        notEqual(pressEnter, focus.pressEnter) &&
          yFocus === 2 &&
          this.setState(prevState => ({ accepted: !prevState.accepted }))
        notEqual(pressEnter, focus.pressEnter) &&
          yFocus === 3 &&
          accepted &&
          history.push('/sign-up/step3')
      }
    }
  }

  render() {
    const {
      focus: { xFocus }
    } = this.props
    const { yFocus, accepted } = this.state
    const focussed = isFocussed(this.props)
    return (
      <StyledDiv>
        <h2>{'Privacy Policy'}</h2>
        <ScrollableContainer xFocusId={1} yFocusId={1} {...{ yFocus }}>
          <Copy />
        </ScrollableContainer>
        <StyledAcceptanceC2A
          {...{ accepted }}
          focussed={focussed && yFocus === 2}
        >
          <span />
          <span>
            {
              'I agree to my information being used as set out in the Privacy Policy'
            }
          </span>
        </StyledAcceptanceC2A>
        <Button disabled={!accepted} focussed={focussed && yFocus === 3}>
          {'Continue'}
        </Button>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(Step2)
export default connected
