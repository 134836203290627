import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { history } from 'store'
import { moveXFocus } from 'App/focus/actions'
import {
  StyledSection,
  StyledDiv,
  StyledContainer,
  StyledScrollbar
} from './Article.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class Article extends Component {
  state = {
    yFocus: 0,
    scrollComplete: false,
    contentScroll: 0,
    barScroll: 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveUp, moveDown, moveLeft, pressBack },
        moveXFocus
      } = this.props
      const { yFocus, scrollComplete } = this.state
      const { translateY } = this
      notEqual(moveUp, focus.moveUp) && yFocus > 0 && translateY(-1)
      notEqual(moveDown, focus.moveDown) && !scrollComplete && translateY(1)
    }
  }

  translateY = step => {
    if (
      this.containerMask &&
      this.containerEl &&
      this.textControllerStick &&
      this.textControllerBar
    ) {
      const yFocus = this.state.yFocus + step
      const nextPosition = yFocus * 150 // height to jump when scrolling
      const max =
        this.containerEl.clientHeight - this.containerMask.clientHeight - 200 // maximum scrollable height with extra offset when reaching end of scrolling height to give a feeling of scroll completion
      const contentScroll =
        -nextPosition > max ? Math.min(0, -nextPosition) : Math.min(0, max)
      const scrollComplete = -nextPosition < max
      const barScroll = Math.max(
        0,
        (this.textControllerStick.clientHeight -
          this.textControllerBar.clientHeight) *
          (contentScroll / max)
      )
      this.setState({ contentScroll, barScroll, scrollComplete, yFocus })
    }
  }

  getTranslate = scroll => ({ transform: 'translate(0,' + scroll + 'px)' })

  render() {
    const { contentScroll, barScroll } = this.state
    const { getTranslate } = this
    const { type } = this.props
    const focussed = isFocussed(this.props)
    return (
      <StyledDiv ref={div => (this.containerEl = div)}>
        <StyledContainer
          ref={div => (this.containerMask = div)}
          style={getTranslate(contentScroll)}
        >
          {this.props.children}
        </StyledContainer>
        <StyledScrollbar
          ref={div => (this.textControllerStick = div)}
          {...{ focussed }}
        >
          <div
            style={getTranslate(barScroll)}
            ref={div => (this.textControllerBar = div)}
          />
        </StyledScrollbar>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus }
)(Article)
export default connected
