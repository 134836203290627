import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { history } from 'store'
import { notEqual } from 'utils'
import { StyledSection, StyledButton } from './404.style'

const isFocussed = ({ xFocus, xFocusId }) => xFocus === xFocusId

class Four04 extends Component {
  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, moveUp, moveDown, pressEnter, pressBack },
        moveXFocus
      } = this.props
      notEqual(focus.moveLeft, moveLeft) && moveXFocus(-1)
      notEqual(focus.pressBack, pressBack) && history.goBack()
      notEqual(focus.pressEnter, pressEnter) && history.push('/home')
    }
  }

  render() {
    const { REACT_APP_BUILD_TYPE } = process.env
    const focussed = true
    return (
      <StyledSection>
        <h2>{"Sorry - we couldn't find that page!"}</h2>
        <StyledButton focussed={focussed}>{'Back to homepage'}</StyledButton>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })

const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(Four04)
export default connected
