import React from 'react'
import Lock from 'components/Lock'
import { StyledDiv, StyledTile } from './EpisodeTile.style'

const isFocussed = ({
  xFocus,
  xFocusId,
  yFocus,
  yFocusId,
  focusPosition,
  itemIndex
}) => xFocus === xFocusId && yFocus === yFocusId && itemIndex === 0

const Tile = props => {
  const {
    item: { preview_image_url, title },
    focusPosition,
    showLock
  } = props
  const focussed = isFocussed(props)
  return (
    <StyledDiv>
      <StyledTile
        {...{ focussed }}
        style={{
          backgroundImage: `url(${preview_image_url.replace(
            'http://',
            'https://'
          )})`
        }}
      >
        <img
          src={preview_image_url.replace('http://', 'https://')}
          alt={title}
          style={{ opacity: 0 }}
        />
      </StyledTile>
      {showLock && <Lock />}
    </StyledDiv>
  )
}

export default Tile
