import styled from 'styled-components'

export const StyledDiv = styled.div`
  width: 100%;
  padding-bottom: 5vh;
  h2 {
    margin-left: 2.5vw;
    margin-bottom: 4vh;
  }
  h3 {
    margin-top: 4vh;
  }
`

export const CarouselContainer = styled.div`
  display: flex;
`
