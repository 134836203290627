import {
  LOGIN_USERNAME,
  LOGIN_PASSWORD,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  SET_LWA_TOKEN,
  GET_LWA_TOKEN,
  UPDATE_USER
} from './actions'

const initialState = {
  email: '',
  password: '',
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  accessToken: false // LWA access token
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case LOGIN_USERNAME: {
      return {
        ...state,
        errors: [],
        email: payload
      }
    }
    case LOGIN_PASSWORD: {
      return {
        ...state,
        errors: [],
        password: payload
      }
    }
    // Set the requesting flag and append a message to be shown
    case LOGIN_REQUEST: {
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: []
      }
    }

    // Set the requesting flag and append a message to be shown
    case SET_LWA_TOKEN: {
      return {
        ...state,
        accessToken: payload
      }
    }

    case GET_LWA_TOKEN: {
      return {
        ...state,
        accessToken: payload
      }
    }

    // Reset the login state.
    case LOGIN_SUCCESS: {
      return {
        email: '',
        password: '',
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        accessToken: false
      }
    }

    // Append the error returned from our API
    // Set the success and requesting flags to false
    case LOGIN_ERROR: {
      return {
        ...state,
        errors: state.errors.concat([
          {
            type: payload,
            time: new Date()
          }
        ]),
        messages: [],
        requesting: false,
        successful: false,
        accessToken: false
      }
    }

    case UPDATE_USER: {
      return state
    }

    // Reset the login state.
    case LOGOUT_SUCCESS: {
      return {
        email: '',
        password: '',
        errors: [],
        messages: [],
        requesting: false,
        successful: false,
        accessToken: false
      }
    }

    default:
      return state
  }
}

export default reducer
