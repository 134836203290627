import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getKey,
  notEqual,
  userHasSubscription,
  userHasActiveSubscription
} from 'utils'
import { history } from 'store'
import Carousel from 'components/Carousel'
import Hero from 'components/Hero'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { createPlayer } from 'components/Player/actions'
import logo from 'img/hopster-logo-white.svg'
import { StyledSection, ScrollableContainer } from './Home.style'

const isFocussed = ({ xFocus, xFocusId }) => xFocus === xFocusId

const findObjectById = (hid, freeVideos, idType) =>
  freeVideos.find(video => video[idType] === hid)

const titles = ['For You', 'Shows', 'Music']

class Home extends Component {
  state = {
    xFocusArray: [],
    yFocus: 0,
    scrollHeight: [],
    carousels: [],
    pageReady: false
  }

  componentDidMount() {
    const {
      videos: { carousels, freeVideos, lastWatched },
      resetXFocus
    } = this.props
    const xFocusArray = []
    const scrollHeight = []
    carousels.map((col, i) => {
      xFocusArray.push(0)
      const freeShows = []
      const nonFreeShows = []
      col.collections.map(c => {
        if (findObjectById(c.hid, freeVideos, 'series_hid')) {
          freeShows.push(c)
        } else {
          nonFreeShows.push(c)
        }
        carousels[i].collections = freeShows.concat(nonFreeShows)
      })
      return scrollHeight.push(false)
    })
    resetXFocus(1)
    this.setState({
      xFocusArray,
      carousels,
      pageReady: true
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveUp, moveDown, moveLeft, moveRight, pressEnter, pressBack },
        videos: { freeVideos },
        moveXFocus,
        handleMoveXFocus
      } = this.props
      const { yFocus, xFocusArray, carousels } = this.state
      const { handleMoveUp, handleMoveDown, handleMove, getRouteForShow } = this
      notEqual(moveUp, focus.moveUp) && handleMoveUp()
      notEqual(moveDown, focus.moveDown) && handleMoveDown(carousels.length - 1)
      notEqual(moveLeft, focus.moveLeft) && handleMove(-1)
      notEqual(moveRight, focus.moveRight) &&
        xFocusArray[yFocus] < carousels[yFocus].collections.length - 1 &&
        handleMove(1)
      notEqual(pressEnter, focus.pressEnter) &&
        getRouteForShow(carousels[yFocus], freeVideos)
      notEqual(pressBack, focus.pressBack) && handleMoveXFocus(-1)
    }
  }

  getRouteForShow = (carousel, freeVideos) => {
    const { user, createPlayer } = this.props
    const { xFocusArray, yFocus, carousels } = this.state
    const item = carousel.collections[xFocusArray[yFocus]]
    const route =
      userHasSubscription(user) || this.findObjectById(item.hid, freeVideos)
        ? userHasActiveSubscription(user) ||
          this.findObjectById(item.hid, freeVideos)
          ? `/show/${item.hid}`
          : `/account`
        : !!user
        ? '/account'
        : '/'
    return history.push(route)
  }

  findObjectById = (hid, freeVideos) =>
    freeVideos.find(video => video.series_hid === hid)

  handleMoveUp = () => {
    const { yFocus } = this.state
    yFocus >= 1 && this.setState(prevState => ({ yFocus: yFocus - 1 }))
  }

  handleMoveDown = itemIndex => {
    const { yFocus } = this.state
    yFocus < itemIndex && this.setState(prevState => ({ yFocus: yFocus + 1 }))
  }

  handleMove = step => {
    const { yFocus, xFocusArray } = this.state
    const { moveXFocus } = this.props
    const focus = xFocusArray[yFocus]
    return focus === 0 && step < 0
      ? moveXFocus(-1)
      : xFocusArray.splice(yFocus, 1, focus + step)
  }

  measureHeight = (height, position) => {
    const { scrollHeight } = this.state
    scrollHeight[position] = height
    this.setState({ scrollHeight })
  }

  render() {
    const {
      xFocus,
      xFocusId,
      handleMoveXFocus,
      videos: { lastWatched, shows }
    } = this.props
    const {
      xFocusArray,
      yFocus,
      pageReady,
      carousels,
      scrollHeight
    } = this.state
    const { measureHeight } = this
    return (
      pageReady && (
        <StyledSection>
          <img src={logo} alt={'Hopster'} />
          <Hero {...{ xFocusArray, yFocus, carousels, shows }} />
          <ScrollableContainer>
            {carousels.slice(yFocus, yFocus + 2).map((carousel, i) => (
              <Carousel
                key={`carousel_${i}`}
                tileType={'show'}
                title={carousel.name}
                carousel={carousel.collections}
                yFocusId={i}
                focusPosition={xFocusArray[yFocus]}
                {...{
                  xFocus,
                  xFocusId,
                  yFocus,
                  handleMoveXFocus,
                  measureHeight,
                  scrollHeight
                }}
              />
            ))}
          </ScrollableContainer>
        </StyledSection>
      )
    )
  }
}

const mapStateToProps = ({ videos, focus, user }) => ({ videos, focus, user })
const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus, createPlayer }
)(Home)
export default connected
