import styled from 'styled-components/macro'
import { palette, Section } from 'style'
import bgHelp from 'img/bg-help-2112.jpg'

export const StyledSection = styled(Section)`
  background-image: url(${bgHelp});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% auto;
  align-items: flex-end;
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
  height: 100vh;
  margin-right: 5vw;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    &:first-of-type {
      align-items: flex-end;
      flex-direction: row;
    }
    &:last-of-type {
      height: 20vh;
      flex-direction: row;
    }
  }

  h3,
  h4 {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    text-align: center;
  }

  h4 {
    margin-bottom: 8vh;
  }
`

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  height: 15vh;
  color: ${palette.white};
  font-size: 1.8vw;
  font-weight: bold;
  text-decoration: ${({ focussed }) => (focussed ? 'underline' : 'none')};
  padding-left: 2vw;
  &:first-of-type {
    padding-right: 2vw;
    padding-left: 0;
    position: relative;
    &:after {
      content: '|';
      position: absolute;
      right: 0;
    }
  }
`
