import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { Qwerty } from './Qwerty'
import { moveXFocus } from 'App/focus/actions'
import { StyledContainer, StyledDiv } from './Keyboard.style'

const row1 = ['a', 'b', 'c', 'd', 'e', 'f']
const row1_alt = [',', '&', '#', "'", '"', ':']
const row2 = ['g', 'h', 'i', 'j', 'k', 'l']
const row2_alt = [';', '?', '<', '>', '^', '*']
const row3 = ['m', 'n', 'o', 'p', 'q', 'r']
const row3_alt = ['(', ')', '[', ']', '{', '}']
const row4 = ['s', 't', 'u', 'v', 'w', 'x']
const row4_alt = ['£', '$', '€', '~', '%', '!']
const row5 = ['y', 'z', 1, 2, 3, 4]
const row5_alt = ['+', '=', 1, 2, 3, 4]
const row6 = [5, 6, 7, 8, 9, 0]
const row7 = ['.com', '@', '.', '-', '_']
const row8 = ['CAPS', '@#!', 'space']
const row9 = ['delete', 'enter']
//const row5 = ['CAPS', '@#!', 'Space', '.co.uk', '.com']

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class Keyboard extends Component {
  state = {
    xFocus: 0,
    yFocus: 0,
    caps: false,
    alt: false,
    keyboardRows: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveUp, moveDown, moveLeft, moveRight, pressEnter, pressBack },
      pressedDelete,
      moveXFocus,
      handleMoveXFocus
    } = this.props
    const { xFocus, yFocus, keyboardRows } = this.state
    const { movedUp, movedDown, movedRight, pressedEnter } = this
    if (isFocussed(this.props)) {
      notEqual(moveUp, focus.moveUp) && yFocus !== 0 && movedUp(xFocus)
      notEqual(moveDown, focus.moveDown) &&
        yFocus < keyboardRows.length - 1 &&
        movedDown(xFocus)
      notEqual(moveLeft, focus.moveLeft) &&
        xFocus !== 0 &&
        this.setState({ xFocus: xFocus - 1 })
      notEqual(moveLeft, focus.moveLeft) && xFocus === 0 && moveXFocus(-1)
      notEqual(moveRight, focus.moveRight) && movedRight(xFocus)
      notEqual(pressEnter, focus.pressEnter) &&
        pressedEnter(keyboardRows[yFocus][xFocus])
      notEqual(pressBack, focus.pressBack) && moveXFocus(-1)
    }
  }

  componentDidMount() {
    const rows = [row1, row2, row3, row4, row5, row6, row7, row8, row9]
    this.setState({ keyboardRows: rows, keyboardReady: true })
  }

  setAltChars = alt => {
    const rows = alt
      ? [
          row1_alt,
          row2_alt,
          row3_alt,
          row4_alt,
          row5_alt,
          row6,
          row7,
          row8,
          row9
        ]
      : [row1, row2, row3, row4, row5, row6, row7, row8, row9]
    this.setState({ alt: alt, keyboardRows: rows, keyboardReady: true })
  }

  pressedEnter = key => {
    const { pressedEnter, pressedDelete, moveXFocus } = this.props
    const { xFocus, yFocus, caps, alt, keyboardRows } = this.state
    keyboardRows[yFocus][xFocus] === 'CAPS'
      ? this.setState(prevState => ({ caps: !prevState.caps }))
      : keyboardRows[yFocus][xFocus] === '@#!'
      ? this.setAltChars(!alt)
      : keyboardRows[yFocus][xFocus] === 'delete'
      ? pressedDelete()
      : keyboardRows[yFocus][xFocus] === 'space'
      ? pressedEnter(' ', caps)
      : keyboardRows[yFocus][xFocus] === 'enter'
      ? moveXFocus(1)
      : pressedEnter(keyboardRows[yFocus][xFocus], caps)
  }

  movedUp = () => {
    const { yFocus } = this.state
    this.setState({ yFocus: yFocus - 1 })
  }

  movedDown = xFocus => {
    const { yFocus } = this.state
    if (yFocus > 4) {
      const modifiedXFocus = xFocus < 2 ? 0 : xFocus < 4 ? 1 : 2
      this.setState({ xFocus: modifiedXFocus, yFocus: yFocus + 1 })
    } else {
      this.setState({ yFocus: yFocus + 1 })
    }
  }

  movedRight = xFocus => {
    const { yFocus, keyboardRows } = this.state
    const { moveXFocus } = this.props
    xFocus < keyboardRows[yFocus].length - 1
      ? this.setState(prevState => ({ xFocus: prevState.xFocus + 1 }))
      : moveXFocus(1)
  }

  render() {
    const { xFocus, yFocus, caps, keyboardRows } = this.state
    return (
      <StyledContainer>
        <StyledDiv>
          <Qwerty
            isFocussed={isFocussed(this.props)}
            {...{ xFocus, yFocus, caps }}
            rows={keyboardRows}
          />
        </StyledDiv>
      </StyledContainer>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })

const connected = connect(
  mapStateToProps,
  { moveXFocus }
)(Keyboard)

export default connected
