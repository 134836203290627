import styled from 'styled-components'

export const StyledDiv = styled.div`
  min-width: 20vw;
  min-height: 20vw;
  margin-right: 1vw;
  position: relative;
  > div {
    position: absolute;
    z-index: 0;
    margin: 2vw 0 0 2vw;
    width: 16vw;
    height: 16vw;
    border-radius: 1.2vw;
    &.focussed {
      background-color: #fff;
      &.circle {
        width: 17vw;
        height: 17vw;
        margin: 1.5vw;
        border-radius: 50%;
      }
      &.tilted {
        transform: rotate(45deg);
        width: 15vw;
        height: 15vw;
        margin: 2.5vw;
      }
    }
  }
  img {
    position: relative;
    z-index: 1;
    width: 20vw;
  }
`
