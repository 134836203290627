import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getKey, userHasSubscription, userHasActiveSubscription } from 'utils'
import ShowTile from 'components/ShowTile'
import EpisodeTile from 'components/EpisodeTile'
import LastWatchedTile from 'components/LastWatchedTile'
import { StyledDiv, CarouselContainer } from './Carousel.style'

class Carousel extends Component {
  findObjectById = (hid, freeVideos, idType) =>
    freeVideos.find(video => video[idType] === hid)
  render() {
    const {
      title,
      carousel,
      tileType, // Whether to show show or episode format
      xFocusId,
      xFocus,
      yFocusId,
      yFocus,
      measureHeight,
      scrollHeight,
      user,
      focusPosition,
      videos: { freeVideos, shows }
    } = this.props
    const slicedCarousel = carousel.slice(focusPosition, focusPosition + 5)
    return (
      <StyledDiv
        ref={div =>
          div &&
          !scrollHeight[yFocusId] &&
          measureHeight(div.offsetHeight, yFocusId)
        }
      >
        <h2>{title}</h2>
        <CarouselContainer>
          {slicedCarousel.map((item, itemIndex) => {
            return tileType === 'show' ? (
              <ShowTile
                key={getKey()}
                showLock={
                  (!userHasSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'series_hid')) ||
                  (!userHasActiveSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'series_hid'))
                }
                {...{
                  item,
                  itemIndex,
                  focusPosition,
                  xFocusId,
                  xFocus,
                  yFocusId,
                  yFocus
                }}
              />
            ) : tileType === 'lastWatched' ? (
              <LastWatchedTile
                key={getKey()}
                showLock={
                  (!userHasSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'hid')) ||
                  (!userHasActiveSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'hid'))
                }
                {...{
                  item,
                  shows,
                  itemIndex,
                  focusPosition,
                  xFocusId,
                  xFocus,
                  yFocusId,
                  yFocus
                }}
              />
            ) : (
              <EpisodeTile
                key={getKey()}
                showLock={
                  (!userHasSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'hid')) ||
                  (!userHasActiveSubscription(user) &&
                    !this.findObjectById(item.hid, freeVideos, 'hid'))
                }
                {...{
                  item,
                  itemIndex,
                  focusPosition,
                  xFocusId,
                  xFocus,
                  yFocusId,
                  yFocus
                }}
              />
            )
          })}
        </CarouselContainer>
        {tileType === 'episode' && (
          <Fragment>
            <h3>{carousel[focusPosition].title}</h3>
            <p>{carousel[focusPosition].desc}</p>
          </Fragment>
        )}
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ videos, user, focus, player }) => ({
  videos,
  user,
  focus,
  player
})

const connected = connect(
  mapStateToProps,
  {}
)(Carousel)

export default connected
