import styled from 'styled-components'
import { Section } from 'style'
import checkbox from 'img/checkbox.svg'
import checkboxUnchecked from 'img/checkbox-empty.svg'
import checkboxFocussed from 'img/checkbox-focussed.svg'
import checkboxUncheckedFocussed from 'img/checkbox-empty-focussed.svg'

export const StyledDiv = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 92.5vw;
  margin-left: 7.5vw;
  height: 100vh;

  h2 {
    padding-top: 8vh;
    margin-bottom: 12vh !important;
  }
  h3 {
    margin-bottom: 10vh;
  }
  h4 {
    margin-bottom: 6vh;
    text-align: center;
  }
`

export const StyledAcceptanceC2A = styled.h4`
  margin: 4vh auto;
  display: flex;
  > span {
    &:first-of-type {
      background-image: url(${({ accepted, focussed }) =>
        accepted
          ? focussed
            ? checkboxFocussed
            : checkbox
          : focussed
          ? checkboxUncheckedFocussed
          : checkboxUnchecked});
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      width: 2vw;
      height: 2vw;
    }
  }
`
