import styled from 'styled-components'
import { palette } from 'style'

export const StyledDiv = styled.div`
  width: 10vw;
  height: 4vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ focussed }) =>
    focussed ? palette.orange : palette.white};
  color: ${({ focussed }) => (focussed ? palette.white : palette.black)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
