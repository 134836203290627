import React, { Fragment } from 'react'

export const Copy = () => (
  <Fragment>
    <p>
      {
        'Welcome to Hopster – the beautifully balanced playroom for the under–sixes.'
      }
    </p>
    <p>
      {
        'Hopster is owned by our parent company, Plato Media Limited, a UK company registered at 1 Vincent Square, London, SW1P 2PN. At Hopster, we are committed to protecting you and your family’s personal information. We want Hopster to be a safe and enjoyable environment for everyone. In this privacy policy we’ll explain the kinds of questions we’ll ask you and what we do with the information you give us. Hopster has been certified by kidSAFE Seal Program and we comply with the Children’s Online Privacy Protection Act, (COPPA) a U.S. law that governs the use and collection of information online from kids under 13. To learn more about COPPA, consult this simple one-page informational guide from the kidSAFE Seal Program.'
      }
    </p>
    <p>
      {
        'We have designed Hopster to offer premium children’s cartoons and games that can be accessed via tablets, smartphones, connected TVs or our website. This privacy policy covers any interaction with Hopster, however your child chooses to access it.'
      }
    </p>
    <p>
      {
        'Our privacy policy will be updated from time to time – you can always come back here and check for any changes. Alternatively, if you want to be notified of any changes, make sure you subscribe to the Hopster newsletter, which will update you on any material revisions of this privacy policy. If we make material changes that affect the handling of personal information collected from children users, we will first notify, and if necessary, obtain the prior consent of a parent or legal guardian.'
      }
    </p>
    <h3>{'kidSAFE Seal Program'}</h3>
    <p>
      {
        'Hopster is certified by the kidSAFE Seal Program. The kidSAFE Seal Program is an independent safety certification service and seal-of-approval program designed exclusively for children-friendly websites and technologies, including kid-targeted game sites, educational services, virtual worlds, social networks, mobile apps, tablet devices, and other similar interactive services and technologies. Click on the seal or go to kidSAFE Seal website for more information.'
      }
    </p>
    <h3>{'What information we collect and how we collect it'}</h3>
    <h4>{'1) Registration'}</h4>
    <p>
      {
        'When registering for Hopster we collect data from you (the parent) such as name, contact information, demographic information, financial information (such as payment methods), IP address, browser, OS, site activity and cookie information. This is necessary as part of the normal process of operating the Hopster service and to allow us to give you access to Hopster.'
      }
    </p>
    <p>
      {
        'As part of the registration process for your child, we require demographic information (gender, country of residence and birth date); we encourage you to submit this information on behalf of your kids so we can provide you with a more personalised experience. We limit the information we collect, both actively (i.e. what we ask about your kids) and passively (what we collect through use of web tracking mechanisms like “cookies”) to that which is necessary for kids to enjoy everything that Hopster has to offer.'
      }
    </p>
    <p>{'The purpose of our data collection is as follows:'}</p>
    <ul>
      <li>{'To allow access to the site'}</li>
      <li>
        {
          'To create a unique account profile (for personalisation, reward points, etc.)'
        }
      </li>
      <li>
        {
          'To notify/obtain consent from parents; to give you access to parental controls'
        }
      </li>
      <li>{'Password reset purposes'}</li>
      <li>{'To set up billing'}</li>
      <li>
        {'To create a personalised world for the child using the service'}
      </li>
      <li>{'To allow us to send personalised gifts and messages'}</li>
      <li>{'To improve and otherwise maintain the Hopster experience'}</li>
      <li>
        {
          'Any other reasonable use of the data in the course of doing business or as part of a new feature'
        }
      </li>
    </ul>
    <h4>{'2) Other Features'}</h4>
    <p>
      {
        'From time to time we may request additional information as part of providing an enhanced service. This may include, but may not be limited to:'
      }
    </p>
    <ul>
      <li>{'Wish list / tell-a-friend / invite-friend / e-cards / etc.'}</li>
      <li>{'Newsletter sign-up form'}</li>
      <li>{'Customer support'}</li>
      <li>{'Periodic entries for contests and sweepstakes'}</li>
      <li>{'Membership purchases (see e-commerce section below)'}</li>
      <li>
        {
          'We may require additional contact information for yourself or a third party that you wish to benefit from one of the above services – by providing us with a third party’s contact details you assert to us that you have a genuine reason to believe that they would benefit from receiving communications from Hopster and indemnify Hopster from any false recommendations leading to legal action.'
        }
      </li>
    </ul>
    <p>
      {
        'Registration will be required for all of these above additional services.'
      }
    </p>
    <h4>{'3) Cookies'}</h4>
    <p>
      {
        'Cookies are small text files that we place in visitors’ computer browsers to store their preferences. Our cookies themselves do not contain any personal information. Our cookies may reference the personal information you provided during registration. We reference the information you provided via an encrypted identifier. Where we link these unique identifiers with your personal information, we will treat the unique identifiers as personal information as long as they are linked. We use the registration information you provided to contact you if you lose your password or contact you about updates and changes at Hopster.'
      }
    </p>
    <p>
      {
        'We use both session ID cookies and persistent cookies. We use session cookies to make it easier for you to navigate Hopster. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser’s “help” file. More info about cookies in this wikipedia article.'
      }
    </p>
    <p>
      {
        'When your kids use Hopster, we collect certain non-personal information by placing and accessing Hopster cookies on the device they are using. Cookies do not contain any personal information, so we do not use cookies (or other covert or non-consensual methods) to acquire contact information about your kids or other users of the device.'
      }
    </p>
    <p>
      {
        'This privacy policy does not cover any cookies used by third party systems in Hopster (See section below on ‘How Hopster uses the information it collects’).'
      }
    </p>
    <h4>{'4) Orders'}</h4>
    <p>
      {
        'You are able to purchase a Hopster subscription for your kids using a variety of third-party services including Stripe, Apple, Google Play and more. For information on how they handle your personal information please consult your payment provider. We do not have access to your payment information directly – simply a unique reference to your account with your payment provider. Optional subscription after trial. Cancel auto-renewal anytime through iTunes or Google Play.'
      }
    </p>
    <h3>{'HOPSTER SUBSCRIPTION ITUNES'}</h3>
    <ul>
      <li>{'Unlimited access to everything while subscribed'}</li>
      <li>
        {
          'Payment will be charged to iTunes Account at confirmation of purchase'
        }
      </li>
      <li>
        {
          'Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period'
        }
      </li>
      <li>
        {
          'Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal'
        }
      </li>
      <li>
        {
          'Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user’s Account Settings after purchase'
        }
      </li>
      <li>
        {
          'Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.'
        }
      </li>
    </ul>
    <h3>{'HOPSTER SUBSCRIPTION GOOGLE PLAY'}</h3>
    <ul>
      <li>
        {
          'Unlimited access to everything while subscribed. No cancellation fees'
        }
      </li>
      <li>
        {
          'Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period'
        }
      </li>
      <li>
        {
          'Cancellation of your subscription will take effect after existing subscription period ends'
        }
      </li>
      <li>
        {
          'Price may vary according to subscription length, location, and/or promotion'
        }
      </li>
      <li>
        {
          'Payment will be charged to your Google Account at confirmation of purchase'
        }
      </li>
      <li>
        {
          'Account will be charged for renewal within 24-hours prior to the end of the current period'
        }
      </li>
      <li>
        {
          'The user may manage subscriptions and turn off auto-renewal by going to Account Settings after purchase'
        }
      </li>
      <li>
        {
          'Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription'
        }
      </li>
    </ul>
    <h3>{'How Hopster uses the information it collects'}</h3>
    <p>{'At Hopster, we host no ads.'}</p>
    <p>
      {
        'As a parent or guardian, your email addresses are used to communicate with you about your kids in relation to their activity at Hopster – for example, what they have watched or played – and to provide you with supporting educational material. It is also used for standard system notifications such as password reminders or to let you know when we have technical issues.'
      }
    </p>
    <p>
      {
        'Profile information about children (such as their name and age) and their activities on Hopster are used in the first instance as reports to you. This information is also used to help personalise the Hopster experience so that appropriate videos and games are presented to them.'
      }
    </p>
    <p>
      {
        'Hopster does not share, rent or trade personal information about any of our registered users, including your kids, with non-affiliated third parties (i.e. companies who are not one of our corporate affiliates or who are not providing support to the internal operations of our company) for their promotional purposes.'
      }
    </p>
    <p>
      {
        'Hopster will, from time to time, share personal information with carefully selected third party companies, who perform services on our behalf. These services may include, but are not limited to: academic and curriculum advice, payment processing; promotion fulfillment; joint marketing promotions; marketing (for Hopster); search engine facilities and delivery of purchased or promotional items.'
      }
    </p>
    <p>
      {
        'These third party companies are covered by their own privacy policies, and we are very careful to select and proactively monitor such companies to ensure that they uphold the same high standards we set for ourselves. We do not share personal information with third party companies for their own marketing purposes, as part of our commitment to following the best practices around privacy and safety and our commitment to the kidSAFE Seal Program.'
      }
    </p>
    <p>
      {
        'Third party companies are strictly prohibited from using your personal information for their own benefit, and will only be provided with the minimum amount of personal information required for them to perform their service. Any personal information processed by a third party company is subject to both this privacy policy and the Data Protection Act 1998 (UK).'
      }
    </p>
    <p>
      {
        'There are other situations in which we may share your personal information:'
      }
    </p>
    <ul>
      <li>
        {
          'Legal disclosures (e.g., law enforcement, investigation, safety issue, etc.)'
        }
      </li>
      <li>{'Business transfers (e.g., sale or acquisition of company)'}</li>
    </ul>
    <p>
      {
        'In the event of a request for a legal disclosure, we will abide by the data protection statutes of the requesting authority. In the case of a business transfer, you will be contacted in advance and informed of the changes.'
      }
    </p>
    <h3>{'Security'}</h3>
    <p>
      {
        'Hopster uses Google Cloud Services to deliver its services and to host all of its data, including your personal information. Google Cloud Services is certified secure by multiple agencies, and you can read all about the security measures employed by Google.'
      }
    </p>
    <p>
      {
        'Within Hopster itself, we make sure that only essential staff have access to your personal information; none of your personal information is stored locally or offline in our offices.'
      }
    </p>
    <h3>{'Parental controls'}</h3>
    <p>
      {
        'Hopster may collect profile information (e.g. name, gender, date of birth) about your kids. This is a completely optional feature.'
      }
    </p>
    <p>
      {
        'If you have opted-in to this feature, we will ensure that you will be able to:'
      }
    </p>
    <ul>
      <li>{'opt out of these features subsequently, if you decide to.'}</li>
      <li>{'view and manage your child’s account'}</li>
      <li>{'delete your child’s account and any data associated with it'}</li>
      <li>
        {
          'request that we stop collecting or using any personal information collected from your child, if applicable.'
        }
      </li>
    </ul>
    <h3>{'Opting out'}</h3>
    <p>
      {
        'If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by following the instructions included in each newsletter. Alternatively, you may contact us by emailing hello@hopster.tv or in writing to the address below.'
      }
    </p>
    <h3>{'Contacting Us'}</h3>
    <p>
      {
        'If you wish to contact us about any privacy questions or concerns you may have, please do contact our team via the website.'
      }
    </p>
    <p>
      {
        'You can also write to us at our parent company, Plato Media Ltd, at our postal address:'
      }
    </p>
    <p>
      <b>{'Plato Media Ltd'}</b>
      {', 1 Vincent Square, London, SW1P 2PN'}
    </p>
    <p>{'Tel: +44 (0)20 7237 0313'}</p>
  </Fragment>
)
