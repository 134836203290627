import styled from 'styled-components'
import bg from 'img/bg-hero.svg'

export const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 4vh;
  height: 45vh;
  background-image: url(${bg});
  background-size: auto 100%;
  overflow: hidden;
  > div {
    height: 45vh;
    background-image: url(${bg});
    background-size: auto 100%;
    width: 45vw;
    position: absolute;
    right: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 5vw;
    > img {
      width: 20vw;
      margin-bottom: 2vh;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -2vw;
      width: 15vw;
      height: 100%;
      background-image: url(${bg});
      background-size: auto 100%;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: skew(-15deg);
      -ms-transform: skew(-15deg);
      transform: skew(-15deg);
      z-index: -1;
    }
  }
  > img {
    height: 45vh;
    position: absolute;
  }
`
