import styled from 'styled-components'
import { Section, palette } from 'style'

export const StyledDiv = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 67.5vw;
  height: 100vh;

  h2 {
    padding-top: 8vh;
    margin-bottom: 12vh !important;
  }
  h3 {
    margin-bottom: 10vh;
  }
  h4 {
    margin-bottom: 6vh;
    text-align: center;
  }

  span.error {
    opacity: 0;
    margin-top: 4vh;
    &.show {
      opacity: 1;
      font-size: 1.40625vw;
      color: white;
      letter-spacing: -0.01vw;
      transition: opacity 0.2s ease-out;
    }
  }
`

export const StyledInputDiv = styled.div`
  width: 12vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ placeHolder }) =>
    placeHolder ? palette.blackOpacity50 : palette.black};
  background-color: ${palette.white};
  font-weight: bold;
  font-size: ${({ placeHolder }) => (placeHolder ? '2vw' : '4vw')};
  border-radius: 1vw;
  margin-bottom: 4vh;
  position: relative;
  &::after {
    content: "${({ error }) => error && error.message}";
    position: absolute;
    width: 18vw;
    top: 0;
    left: -20vw;
    color: #000;
    background-color: ${palette.errorRed};
    padding: 1em 2em;
    border-radius: 1vw;
    opacity: ${({ error }) => (error ? 1 : 0)};
    transition: opacity .25s ease-in;
    font-weight: bold;
  }
`
