import React, { Component } from 'react'
import { connect } from 'react-redux'
import keydown from 'react-keydown'
import { Route } from 'react-router-dom'
import { usedKey, moveXFocus } from 'App/focus/actions'
import Home from 'components/Home'
import Show from 'components/Show'
import Shows from 'components/Shows'
import Menu from 'components/Menu'
import Help from 'components/Help'
import Account from 'components/Account'
import Login from 'components/Login'
import PrivacyPolicy from 'components/PrivacyPolicy'
import Search from 'components/Search'
import SignUp from 'components/SignUp'
import Terms from 'components/Terms'
import Four04 from 'components/404'
import Entitlement from 'components/Entitlement'
import { StyledSection } from './PageWithMenu.style'

class PageWithMenu extends Component {
  handleMoveXFocus = step => {
    const { moveXFocus } = this.props
    moveXFocus(step)
  }

  state = {
    back: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { usedKey } = this.props
    const { REACT_APP_BUILD_TYPE } = process.env
    const {
      keydown: { event }
    } = nextProps
    event && event.which === 8 && usedKey('back') // back button
    event && event.which === 27 && usedKey('back') // backbutton (virgin)
    event && event.which === 461 && usedKey('back') // backbutton (gibtelcom)
    event && event.which === 13 && usedKey('enter') // enter
    event && event.which === 37 && usedKey('left') // left
    event && event.which === 38 && usedKey('up') // up
    event && event.which === 39 && usedKey('right') // right
    event && event.which === 40 && usedKey('down') // down
    event && event.which === 179 && usedKey('playPause') // play / pause android
    event && event.which === 415 && usedKey('playPause') // play (virgin)
    event && event.which === 463 && usedKey('playPause') // pause (virgin)
    event && event.which === 228 && usedKey('fastForward') // android
    event && event.which === 227 && usedKey('rewind') // android
    event && event.which === 402 && usedKey('playPause') // play / pause (gibtelcom / netgem)
    event && event.which === 417 && usedKey('fastForward') // (gibtelcom / netgem / virgin)
    event && event.which === 412 && usedKey('rewind') // (gibtelcom / netgem / virgin)
    event && event.which === 190 && usedKey('fastForward') // > - keyboard hack
    event && event.which === 188 && usedKey('rewind') // < - keyboard hack
    event && event.which === 32 && usedKey('playPause') // space - keyboard hack
  }

  render() {
    const {
      focus: { xFocus }
    } = this.props
    const { handleMoveXFocus } = this
    return (
      <StyledSection>
        <Menu xFocusId={0} {...{ xFocus, handleMoveXFocus }} />
        <Route
          exact
          path={'/home'}
          render={props => (
            <Home xFocusId={1} {...{ xFocus, handleMoveXFocus }} {...props} />
          )}
        />
        <Route
          exact
          path={'/help'}
          render={props => (
            <Help xFocusId={1} {...{ xFocus, handleMoveXFocus }} {...props} />
          )}
        />
        <Route
          exact
          path={'/privacy'}
          render={props => (
            <PrivacyPolicy
              xFocusId={1}
              {...{ xFocus, handleMoveXFocus }}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={'/terms'}
          render={props => (
            <Terms xFocusId={1} {...{ xFocus, handleMoveXFocus }} {...props} />
          )}
        />

        <Route
          exact
          path={'/shows'}
          render={props => (
            <Shows xFocusId={1} {...{ xFocus, handleMoveXFocus }} {...props} />
          )}
        />
        <Route
          exact
          path={'/show/:hid'}
          render={props => (
            <Show xFocusId={1} {...{ xFocus, handleMoveXFocus }} {...props} />
          )}
        />
        <Route
          exact
          path={'/account'}
          render={props => (
            <Account
              xFocusId={1}
              {...{ xFocus, handleMoveXFocus }}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={'/login'}
          render={props => (
            <Login xFocusId={1} {...{ handleMoveXFocus }} {...props} />
          )}
        />
        <Route
          exact
          path={'/search'}
          render={props => (
            <Search xFocusId={1} {...props} {...{ handleMoveXFocus }} />
          )}
        />
        <Route
          path={'/sign-up'}
          render={props => <SignUp {...props} {...{ handleMoveXFocus }} />}
        />
        <Route
          path={'/404'}
          render={props => <Four04 {...props} {...{ handleMoveXFocus }} />}
        />
        <Route
          path={'/entitlement'}
          render={props => <Entitlement {...props} {...{ handleMoveXFocus }} />}
        />
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })

const connected = connect(
  mapStateToProps,
  { usedKey, moveXFocus }
)(PageWithMenu)
export default keydown(connected)
