import styled from 'styled-components'

export const StyledDiv = styled.div`
  height: 70vh;
  position: relative;
  overflow: hidden;
  padding-right: 2.8vw;
`
export const StyledContainer = styled.div`
  transition: all 0.3s ease-out;
  padding-right: 2.5vw;
  p,
  ul,
  h4 {
    margin-bottom: 2vh;
    margin-top: 2vh;
  }
  h3 {
    margin-top: 4vh;
  }
`

export const StyledScrollbar = styled.div`
  position: absolute;
  top: 4vh;
  right: 0;
  width: 1.25vw;
  height: 90%;
  background-color: #fff;
  border-radius: 5px;
  div {
    height: 7vh;
    background-color: ${({ focussed }) =>
      focussed ? '#E72976' : 'rgba(255, 255, 255, 0.8)'};
    border-radius: 5px;
    transition: all 0.3s ease-out;
  }
`
