import styled from 'styled-components'
import { Section } from 'style'

export const StyledSection = styled(Section)`
  padding: 25vh 0 0 7.5vw;
  justify-content: start;
  > img {
    position: absolute;
    right: 3vw;
    top: 5vh;
    width: 10vw;
  }
  > div {
    margin-left: 5vw;
    h2 {
      margin-left: 0;
    }
    p {
      width: 80%;
    }
  }
`
