import styled from 'styled-components'
import { Section } from 'style'

export const StyledSection = styled(Section)`
  justify-content: flex-start;
  > img {
    position: absolute;
    right: 3vw;
    top: 5vh;
    width: 10vw;
  }
`

export const ScrollableContainer = styled.div`
  height: 55vh;
  width: 100%;
  overflow: hidden;
  padding-left: 7.5vw;
`
