import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { notEqual } from 'utils'
import { history } from 'store'
import Button from 'components/Button'
import NumericKeyboard from 'components/NumericKeyboard'
import { StyledDiv, StyledInputDiv } from './AgeGate.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class AgeGate extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveLeft, moveUp, moveDown, pressEnter, pressBack },
      handleContinue,
      moveXFocus
    } = this.props
    if (isFocussed(this.props)) {
      notEqual(moveLeft, focus.moveLeft) && moveXFocus(-1)
      notEqual(pressEnter, focus.pressEnter) && handleContinue()
    }
  }
  render() {
    const { dob, errors } = this.props
    const focussed = isFocussed(this.props)
    return (
      <StyledDiv>
        <h2>{'Safety first - are you over 18?'}</h2>
        <h4>{'Please enter your own birth year:'}</h4>
        <StyledInputDiv placeHolder={!dob}>
          {!dob ? 'YYYY' : dob}
        </StyledInputDiv>
        <Button {...{ focussed }}>{'Continue'}</Button>
        <span className={!!errors ? 'error show' : 'error'}>
          {errors.message}
        </span>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus }
)(AgeGate)

export default connected
