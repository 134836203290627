import styled from 'styled-components'
import lock from 'img/icons/lock.svg'

export const StyledSpan = styled.span`
  width: 5vw;
  height: 5vw;
  display: block;
  position: absolute;
  background-image: url(${lock});
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-radius: 50%;
  z-index: 2;
  right: 1.2vw;
  bottom: 0;
`
