import styled from 'styled-components'
import { Section, palette } from 'style'

export const StyledSection = styled(Section)`
  flex-direction: column;
  text-align: center;
  p {
    margin-bottom: 4vh;
  }
`

export const StyledButton = styled.div`
  width: 25vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ focussed }) => (focussed ? palette.white : palette.black)};
  background: ${({ focussed }) => (focussed ? palette.orange : palette.white)};
  margin-top: 8vh;
  border-radius: 1vw;
  font-size: 2.5vw;
  font-family: 'Gotham Book', sans-serif;
`
