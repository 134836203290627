import React from 'react'
import Lock from 'components/Lock'
import { StyledDiv } from './ShowTile.style'

const isFocussed = ({
  xFocus,
  xFocusId,
  yFocus,
  yFocusId,
  focusPosition,
  itemIndex
}) => xFocus === xFocusId && itemIndex === 0

const Tile = props => {
  const {
    item: { leaf_url, title, content_type },
    focusPosition,
    showLock
  } = props
  const width = document.getElementById('root').clientWidth / 2.5
  let className = isFocussed(props)
    ? content_type === 'MUSICS'
      ? 'focussed tilted'
      : content_type === 'VIDEOS'
      ? 'focussed circle'
      : 'focussed'
    : null

  return (
    <StyledDiv>
      <div {...{ className }} />
      {showLock && <Lock />}
      <img
        src={`${leaf_url.replace('http://', 'https://')}=s${~~width}`}
        alt={''}
      />
    </StyledDiv>
  )
}

export default Tile
