import axios from 'axios'

const { NODE_ENV, REACT_APP_BUILD_TYPE } = process.env

/**
 Iterator utility for map() keys
 */
let key = 0
export const getKey = () => key++

/**
 * Returns an object to be sent as an Auth Header
 * @param {string} token - locally stored token
 */
export const getAuthHeaders = token => {
  return {
    headers: {
      Authorization: `Hopster ${token}`
    }
  }
}

export const getBrightcoveAuthHeaders = () => {
  return {
    headers: {
      Accept:
        'application/json;pk=' +
        'BCpkADawqM0jRqXhnQd_rnegX9gjS3CHDbQvXjFGPVFE-KZ8wlAzrIDapWYE4dMCHxuXpYv7706Ea5I_YMtfYD-uggCmh25A-RduKOQ3cv2wL1aANMUpqqNPk61-ptPcygS7c7r6ya0us_KM'
    }
  }
}

/**
 * Returns object with id from an array of objects
 * @param {string} id
 * @param {array} entities
 */
export const getEntityById = (id, entities) =>
  entities.find(e => (e.id === id ? e : false))

/**
 * Accepts any 2 types and ascertains if they are equal
 * @param {any} thing1
 * @param {any} thing2
 */
export const notEqual = (thing1, thing2) => thing1 !== thing2

/**
 * Returns boolean as to a user's subscription status
 * @param {object} user
 **/
export const userHasSubscription = user => {
  if (user) {
    const { customer_status } = user
    return (
      customer_status === 'Subscriber' ||
      customer_status === 'SUBSCRIBED' ||
      customer_status === 'Trialist'
    )
  }
  return false
}

/**
 * Returns boolean as to a user's subscription being in-date
 * @param {object} user
 **/
export const userHasActiveSubscription = user => {
  if (user) {
    const { sub_expiry } = user
    const today = new Date().toJSON().slice(0, 10) // Get today's date in the same format as the user's sub_expiry
    return today <= sub_expiry.split('T')[0] // Check if the sub_expiry date is greater than or equal to today's date
  }
  return false
}

/* API URL (test / live) */
//fetch("https://hopster-dev.appspot.com/_ah/api/hopster/v1/videos/schedule/free?deviceType=tv&resolution=3&", {"credentials":"omit","headers":{"content-type":"application/json","sec-fetch-mode":"cors"},"referrer":"http://localhost:1337/","referrerPolicy":"no-referrer-when-downgrade","body":null,"method":"GET","mode":"cors"});
export const baseRequest =
  (window.location.hostname ===
      'gibtelecom-htmltv-dot-hopster-dev.appspot.com' ||
    window.location.hostname === 'virgin-htmltv-dot-hopster-dev.appspot.com' ||
    window.location.hostname === 'ooredoo-htmltv-dot-hopster-dev.appspot.com' ||
    //window.location.hostname === '3-0-0-dot-amazon-htmltv-dot-hopster-dev.appspot.com' ||
    window.location.hostname ===
      'gibtelecom-htmltv-dot-hopster-test.appspot.com' ||
    window.location.hostname ===
      'vodafonero-htmltv-dot-hopster-dev.appspot.com') &&
  REACT_APP_BUILD_TYPE != 'vodafonero'
    ? axios.create({
        baseURL: 'https://hopster-dev.appspot.com/_ah/api/hopster/v1/'
      })
    : NODE_ENV === 'development' ||
      window.location.hostname ===
        'ooredoo-htmltv-dot-hopster-test.appspot.com' ||
      window.location.hostname === '3-0-0-dot-amazon-htmltv-dot-hopster-test.appspot.com' ||
      window.location.hostname === '3-0-0-dot-amazon-htmltv-dot-hopster-dev.appspot.com' ||
      window.location.hostname ===
        'vodafonero-htmltv-dot-hopster-test.appspot.com'
    ?
      axios.create({
        baseURL: 'https://hopster-test.appspot.com/_ah/api/hopster/v1/'
      })
    :
      axios.create({
        baseURL: 'https://hopster-service.appspot.com/_ah/api/hopster/v1/'
      })
