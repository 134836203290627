import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { notEqual, getKey } from 'utils'
import { history } from 'store'
import { resetXFocus, moveXFocus } from 'App/focus/actions'

import { StyledSection, StyledDiv, StyledItem } from './Help.style'

const isFocussed = ({ xFocus, xFocusId }) => xFocus === xFocusId

class Help extends Component {
  state = {
    xFocus: 0,
    items: false
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
    let items = []
    items.push({ name: 'privacy', label: 'Privacy Policy' })
    items.push({ name: 'terms', label: 'Terms of Use' })
    this.setState({ items })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveUp, moveDown, pressEnter, moveLeft, moveRight, pressBack },
      moveXFocus,
      handleMoveXFocus
    } = this.props
    const { items, xFocus } = this.state
    const { pressedEnter } = this
    const currentItem = items[xFocus]
    if (isFocussed(this.props)) {
      notEqual(moveLeft, focus.moveLeft) &&
        xFocus > 0 &&
        this.setState({ xFocus: 0 })
      notEqual(moveLeft, focus.moveLeft) && xFocus === 0 && moveXFocus(-1)
      notEqual(moveRight, focus.moveRight) &&
        xFocus === 0 &&
        this.setState({ xFocus: 1 })
      notEqual(pressEnter, focus.pressEnter) && pressedEnter(currentItem)
      notEqual(pressBack, focus.pressBack) && handleMoveXFocus(-1)
    }
  }

  pressedEnter = ({ name }) => history.push(`/${name}`)

  render() {
    const { xFocus, items } = this.state
    return (
      !!items && (
        <StyledSection>
          <StyledDiv>
            <div>
              <h3>
                {
                  'Our customer support team are always happy to assist you with whatever help you need.'
                }
              </h3>
            </div>
            <div>
              <h4>
                {'You can send us an email with your'}
                <br />
                {'query to help@hopster.tv'}
              </h4>
              <h4>
                {'Or you can connect with us via'}
                <br />
                {'Facebook or Twitter'}
              </h4>
            </div>
            <div>
              {items.map(({ label }, i) => {
                const focussed = i === xFocus
                return (
                  <StyledItem key={getKey()} {...{ focussed }}>
                    {label}
                  </StyledItem>
                )
              })}
            </div>
          </StyledDiv>
        </StyledSection>
      )
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { resetXFocus, moveXFocus }
)(Help)
export default connected
