import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import Keyboard from 'components/Keyboard'
import { getKey } from 'utils'
import { history } from 'store'
import { resetXFocus, moveXFocus } from 'App/focus/actions'
import { StyledDiv } from './SearchResults.style'

const isFocussed = ({ focus: { xFocus }, xFocusId }) => xFocus === xFocusId

class SearchResults extends Component {
  state = {
    yFocus: 0,
    searchResultsFocus: {
      yFocus: 0
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveLeft, pressEnter, pressBack, moveDown, moveUp },
      moveXFocus,
      handleMoveXFocus,
      searchResults
    } = this.props
    const { yFocus, searchResultsFocus } = this.state
    const { movedLeft, pressedEnter } = this
    if (isFocussed(this.props)) {
      notEqual(moveLeft, focus.moveLeft) && movedLeft(moveXFocus)
      notEqual(pressEnter, focus.pressEnter) &&
        pressedEnter(moveXFocus, yFocus, searchResults, searchResultsFocus)
      notEqual(pressBack, focus.pressBack) && handleMoveXFocus(-2)
      notEqual(moveUp, focus.moveUp) &&
        yFocus === 1 &&
        searchResultsFocus.yFocus === 0 &&
        this.setState({ yFocus: yFocus - 1 })
      notEqual(moveUp, focus.moveUp) &&
        yFocus === 1 &&
        searchResultsFocus.yFocus > 0 &&
        this.setState({
          searchResultsFocus: { yFocus: searchResultsFocus.yFocus - 1 }
        })
      notEqual(moveDown, focus.moveDown) &&
        yFocus === 0 &&
        searchResults.length > 0 &&
        this.setState({ yFocus: 1 })
      notEqual(moveDown, focus.moveDown) &&
        yFocus === 1 &&
        searchResults.length - 1 > searchResultsFocus.yFocus &&
        this.setState({
          searchResultsFocus: { yFocus: searchResultsFocus.yFocus + 1 }
        })
    }
  }

  movedLeft = moveXFocus => {
    this.setState({
      yFocus: 0,
      searchResultsFocus: {
        yFocus: 0
      }
    })
    moveXFocus(-1)
  }

  pressedEnter = (moveXFocus, yFocus, searchResults, searchResultsFocus) =>
    yFocus === 0
      ? moveXFocus(-1)
      : history.push(`/show/${searchResults[searchResultsFocus.yFocus].hid}`)

  render() {
    const focussed = isFocussed(this.props)
    const { yFocus, searchResultsFocus } = this.state
    const { searchTerm, searchResults } = this.props
    return (
      <StyledDiv>
        <h2>{'Search'}</h2>
        <div className="input-container">
          <div
            className={focussed && yFocus === 0 ? 'input focussed' : 'input'}
          >
            {searchTerm === '' ? (
              <div className="label">{'SEARCH'}</div>
            ) : (
              searchTerm
            )}
          </div>
        </div>
        <div
          className={focussed && yFocus === 1 ? 'results focussed' : 'results'}
        >
          {searchResults.length > 0 &&
            searchResults.map((result, i) => (
              <p
                key={getKey()}
                className={
                  focussed && searchResultsFocus.yFocus === i
                    ? 'focussed'
                    : null
                }
              >
                {result.title}
              </p>
            ))}
        </div>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })

const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(SearchResults)

export default connected
