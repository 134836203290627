import React, { Component } from 'react'
import { connect } from 'react-redux'
import Article from 'components/Article'
import { notEqual } from 'utils'
import { history } from 'store'
import { Copy } from './content'
import { resetXFocus, moveXFocus } from 'App/focus/actions'
import { StyledSection } from './Terms.style'

class Terms extends Component {
  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveLeft, pressBack },
      moveXFocus
    } = this.props
    notEqual(moveLeft, focus.moveLeft) && moveXFocus(-1)
    notEqual(pressBack, focus.pressBack) && history.goBack()
  }

  render() {
    const { xFocusId } = this.props
    return (
      <StyledSection>
        <h2>{'Terms and Conditions'}</h2>
        <Article xFocusId={1}>
          <Copy />
        </Article>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { resetXFocus, moveXFocus }
)(Terms)
export default connected
