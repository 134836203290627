import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { moveXFocus, resetXFocus } from 'App/focus/actions'
import { history } from 'store'
import { notEqual } from 'utils'
import { StyledSection, StyledButton } from './Entitlement.style'

const isFocussed = ({ xFocus, xFocusId }) => xFocus === xFocusId

class Entitlement extends Component {
  state = {
    yFocus: 0
  }

  componentDidMount() {
    const { resetXFocus } = this.props
    resetXFocus(1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveUp, moveDown, pressEnter, pressBack }
      } = this.props
      const { yFocus } = this.state
      notEqual(focus.moveUp, moveUp) &&
        yFocus === 1 &&
        this.setState({ yFocus: 0 })
      notEqual(focus.moveDown, moveDown) &&
        yFocus === 0 &&
        this.setState({ yFocus: 1 })
      notEqual(focus.pressEnter, pressEnter) &&
        yFocus === 0 &&
        window.vmDtvLib.device.exitApp()
      notEqual(focus.pressEnter, pressEnter) &&
        yFocus === 1 &&
        window.vmDtvLib.nav.exitToApp(
          {
            "appUrl":"x-tivo:web:sandbox_app:https://content.dtv.virginmedia.com/htmlApps/tvChannelUpgrades/index.html?channel=2038"
          },
          error => console.log(error)
        )
    }
  }

  render() {
    const { REACT_APP_BUILD_TYPE } = process.env
    const { yFocus } = this.state
    return (
      <StyledSection>
        <h2>{'Oops!'}</h2>
        <h4>
          {'You need to have the Kids Pick to watch this.'}
          <br />
          {'Grab a grown up and press '}
          <b>{'Continue'}</b> {'to find out more.'}
        </h4>
        <StyledButton focussed={yFocus === 0}>{'Cancel'}</StyledButton>
        <StyledButton focussed={yFocus === 1}>{'Continue'}</StyledButton>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })

const connected = connect(
  mapStateToProps,
  { moveXFocus, resetXFocus }
)(Entitlement)
export default connected
