import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginWithAmazonRequest } from 'App/auth/actions'
import { notEqual, getKey } from 'utils'
import { history } from 'store'
import {
  StyledSection,
  StyledDiv,
  StyledLoginC2A,
  StyledC2A,
  StyledImg,
  StyledItem
} from './Onboarding.style'

class Onboarding extends Component {
  state = {
    xFocus: 0,
    yFocus: 0,
    items: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps
    const {
      focus: { moveLeft, moveRight, moveUp, moveDown, pressEnter, pressBack },
      loginWithAmazonRequest
    } = this.props
    const { REACT_APP_BUILD_TYPE } = process.env
    const { xFocus, yFocus, items } = this.state
    notEqual(moveUp, focus.moveUp) &&
      yFocus !== 0 &&
      this.setState(prevState => ({ yFocus: prevState.yFocus - 1 }))
    notEqual(moveDown, focus.moveDown) &&
      yFocus < items.length &&
      this.setState(prevState => ({ yFocus: prevState.yFocus + 1 }))
    notEqual(pressEnter, focus.pressEnter) &&
      yFocus < items.length &&
      history.push(items[yFocus]['route'])
    notEqual(pressEnter, focus.pressEnter) &&
      yFocus === items.length &&
      xFocus === 0 &&
      history.push('/privacy')
    notEqual(pressEnter, focus.pressEnter) &&
      yFocus === items.length &&
      xFocus === 1 &&
      history.push('/terms')
    notEqual(moveLeft, focus.moveLeft) &&
      yFocus === items.length &&
      xFocus > 0 &&
      this.setState({ xFocus: 0 })
    notEqual(moveRight, focus.moveRight) &&
      yFocus === items.length &&
      xFocus === 0 &&
      this.setState({ xFocus: 1 })
    notEqual(pressBack, focus.pressBack) &&
      history.length > 0 &&
      history.goBack()
    /*global navigator */
    /*eslint no-undef: "error"*/
    notEqual(pressBack, focus.pressBack) &&
      REACT_APP_BUILD_TYPE === 'amazon' &&
      history.length === 1 &&
      navigator &&
      navigator.app.exitApp()
  }

  componentDidMount() {
    const { REACT_APP_BUILD_TYPE } = process.env
    const { user } = this.props
    REACT_APP_BUILD_TYPE === 'ooredoo' && history.push('/home')
    REACT_APP_BUILD_TYPE === 'virgin' && history.push('/home')
    REACT_APP_BUILD_TYPE === 'vodafonero' && history.push('/home')
    !!user && !!user.sub_info && !user.sub_info.expired && history.push('/home')
    let items = [],
      footerItems = []
    REACT_APP_BUILD_TYPE === 'amazon' &&
      items.push({
        component: (
          <StyledLoginC2A key={getKey()}>
            {!!user && user.customer_status === 'CHURNED'
              ? 'Subscribe'
              : 'Start your 7 day trial'}
          </StyledLoginC2A>
        ),
        focussed: (
          <StyledLoginC2A key={getKey()} focussed>
            {!!user && user.customer_status === 'CHURNED'
              ? 'Subscribe'
              : 'Start your 7 day trial'}
          </StyledLoginC2A>
        ),
        route: '/sign-up/step1'
      })
    !user
      ? items.push({
          component: <StyledLoginC2A key={getKey()}>{'Login'}</StyledLoginC2A>,
          focussed: (
            <StyledLoginC2A key={getKey()} focussed>
              {'Login'}
            </StyledLoginC2A>
          ),
          route: '/login'
        })
      : items.push({
          component: (
            <StyledLoginC2A key={getKey()}>{'Log out'}</StyledLoginC2A>
          ),
          focussed: (
            <StyledLoginC2A key={getKey()} focussed>
              {'Log out'}
            </StyledLoginC2A>
          ),
          route: '/logout'
        })

    items.push({
      component: (
        <StyledItem key={getKey()}>{'Continue on limited version'}</StyledItem>
      ),
      focussed: (
        <StyledItem key={getKey()} focussed>
          {'Continue on limited version'}
        </StyledItem>
      ),
      route: '/home'
    })
    footerItems.push({ name: 'privacy', label: 'Privacy Policy' })
    footerItems.push({ name: 'terms', label: 'Terms of Use' })
    this.setState({ footerItems, items })
  }

  render() {
    const {
      content: {
        content: {
          Onboarding: { signup_text1, signup_text2, signup_text3 }
        }
      }
    } = this.props
    const { xFocus, yFocus, items, footerItems } = this.state
    const { REACT_APP_BUILD_TYPE } = process.env
    return (
      !!items &&
      !!footerItems && (
        <StyledSection>
          <StyledDiv>
            <div>
              <h2>
                {
                  'Thousands of educational and fun shows, books and music for kids.'
                }
              </h2>
              <h3>{'Access anywhere. Cancel anytime.'}</h3>
            </div>
            <div>
              {items.map((item, i) =>
                yFocus === i ? item.focussed : item.component
              )}
            </div>
            {REACT_APP_BUILD_TYPE === 'amazon' && (
              <p style={{ textAlign: 'center' }}>
                {
                  'Shows available vary by region. £4.99 / €4.99 / $6.99 (USD) / $7.99 (AUD) a month after trial, cancel auto-renewal at anytime. By creating a Hopster account you agree to the Terms of Use.'
                }
              </p>
            )}
            <div>
              {footerItems.map(({ label }, i) => {
                const focussed = yFocus === items.length && xFocus === i
                return (
                  <StyledItem key={getKey()} {...{ focussed }}>
                    {label}
                  </StyledItem>
                )
              })}
            </div>
          </StyledDiv>
        </StyledSection>
      )
    )
  }
}

const mapStateToProps2 = ({ auth, focus, content, user }) => ({
  auth,
  focus,
  content,
  user
})
const connected = connect(
  mapStateToProps2,
  { loginWithAmazonRequest }
)(Onboarding)
export default connected
