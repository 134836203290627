import styled from 'styled-components'
import { Section, palette } from 'style'
import bg from 'img/bg-intro-2112.jpg'
import login from 'img/button-green.png'
import loginFocussed from 'img/button-green-focussed.png'

export const StyledSection = styled(Section)`
  background-image: url(${bg});
  background-position: center;
  align-items: flex-end;
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
  margin-right: 5vw;
  height: 100vh;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    &:first-of-type {
      padding-top: 22vh;
    }
    &:last-of-type {
      height: 15vh;
      flex-direction: row;
    }
  }

  h2 {
    margin-bottom: 5vh;
    text-align: center;
  }
  h3 {
    font-size: 1.8vw;
  }
  p {
    font-family: 'Gotham';
    font-size: 1.2vw;
    line-height: 1.6vw;
  }
`

export const StyledLoginC2A = styled.div`
  width: 35vw;
  height: 10.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.white};
  background-image: url(${({ focussed }) =>
    focussed ? loginFocussed : login});
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-bottom: 5vh;
  font-size: 2.5vw;
  line-height: 2.5vw;
  font-family: 'GothamRounded', sans-serif;
  font-weight: bold;
`

export const StyledC2A = styled.div`
  width: 35vw;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ focussed }) => (focussed ? palette.white : palette.black)};
  background: ${({ focussed }) => (focussed ? palette.orange : palette.white)};
  margin-bottom: 1.5vh;
  border-radius: 1vw;
  font-size: 2.5vw;
  font-family: 'GothamRounded', sans-serif;
  font-weight: bold;
`

export const StyledImg = styled.img`
  border: 4px solid
    ${({ focussed }) => (focussed ? palette.white : 'transparent')};
`

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.white};
  font-size: 1.8vw;
  font-weight: bold;
  text-decoration: ${({ focussed }) => (focussed ? 'underline' : 'none')};
  padding-left: 2vw;
  font-family: 'GothamRounded', sans-serif;
  &:first-of-type {
    padding-right: 2vw;
    padding-left: 0;
    position: relative;
    &:after {
      content: '|';
      position: absolute;
      right: 0;
    }
  }
`
