import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { logoutRequest } from 'App/auth/actions'
import { history } from 'store'
import { getKey, notEqual } from 'utils'
import { StyledDiv, StyledMask } from './Menu.style'

const isFocussed = ({ xFocus, xFocusId, player }) =>
  xFocus === xFocusId && !player

class Menu extends Component {
  state = {
    yFocus: 0,
    topItems: [],
    bottomItems: [],
    menuReady: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const focussed = isFocussed(nextProps)
    if (focussed) {
      const { focus } = nextProps
      const {
        focus: { moveUp, moveDown, moveRight, pressEnter, pressBack },
        handleMoveXFocus
      } = this.props
      const { REACT_APP_BUILD_TYPE } = process.env
      const { yFocus, topItems, bottomItems } = this.state
      const { pressedEnter } = this
      const items = topItems.concat(bottomItems)
      notEqual(moveRight, focus.moveRight) && handleMoveXFocus(1)
      notEqual(moveDown, focus.moveDown) && this.moved(1)
      notEqual(moveUp, focus.moveUp) && this.moved(-1)
      notEqual(pressEnter, focus.pressEnter) &&
        pressedEnter(items[yFocus]['route'])
      /*global navigator */
      /*eslint no-undef: "error"*/
      notEqual(pressBack, focus.pressBack) &&
        REACT_APP_BUILD_TYPE === 'amazon' &&
        yFocus === 0 &&
        navigator &&
        navigator.app.exitApp()
    }
  }

  componentDidMount() {
    const { user } = this.props
    const { REACT_APP_BUILD_TYPE } = process.env
    const topItems = [
      { name: 'home', route: '/home', label: 'Home' },
      //{ name: 'shows', route: '/shows', label: 'Shows' },
      { name: 'search', route: '/search', label: 'Search' },
      { name: 'help', route: '/help', label: 'Help' }
    ]

    // console.log(user)

    const bottomItems = []
    if (
      REACT_APP_BUILD_TYPE === 'virgin' ||
      REACT_APP_BUILD_TYPE === 'ooredoo' ||
      REACT_APP_BUILD_TYPE === 'vodafonero'
    ) {
    } else {
      !user // TODO - logged in no sub (ever)
        ? bottomItems.push({
            name: 'account',
            route: '/login',
            label: 'Account'
          })
        : user.customer_status === 'FREE'
        ? bottomItems.push({
            name: 'account',
            route: '/',
            label: 'Account'
          })
        : user.customer_status === 'CHURNED'
        ? bottomItems.push({
            name: 'account',
            route: '/',
            label: 'Account'
          })
        : bottomItems.push({
            name: 'account',
            route: '/account',
            label: 'Account'
          })
    }

    this.setState({ topItems, bottomItems, menuReady: true })
  }

  moved = step => {
    const { yFocus, bottomItems, topItems } = this.state
    return (
      (yFocus !== 0 || step > 0) &&
      (yFocus < bottomItems.length + topItems.length - 1 || step < 0) &&
      this.setState(prevState => ({ yFocus: prevState.yFocus + step }))
    )
  }

  pressedEnter = url => {
    const { handleMoveXFocus, logoutRequest } = this.props
    handleMoveXFocus(1)
    const route = url.charAt(0) === '/' ? url : '/' + url
    return route === '/logout'
      ? logoutRequest()
      : setTimeout(() => history.push(route), 300)
  }

  render() {
    const focussed = isFocussed(this.props)
    const { pathname } = window.location
    const parentPath = pathname.split('/')
    const { yFocus, topItems, bottomItems, menuReady } = this.state
    return (
      <Fragment>
        {menuReady && (
          <StyledDiv className={focussed && 'open'}>
            <div className={'logo'} />
            {topItems.map((menuItem, i) => {
              const { name, label } = menuItem
              const focussed = yFocus === i
              const current = name === parentPath[1]
              return (
                <div
                  key={getKey()}
                  className={
                    current && focussed
                      ? 'menu-item current focussed'
                      : current
                      ? 'menu-item current'
                      : focussed
                      ? 'menu-item focussed'
                      : 'menu-item'
                  }
                >
                  <div className={`icon ${name}`} />
                  <span className={'menu-item__text'}>{label}</span>
                </div>
              )
            })}
            <div className={'bottom-menu'}>
              {bottomItems.map((menuItem, i) => {
                const { name, label } = menuItem
                const focussed = yFocus === i + topItems.length
                const current = name === parentPath[1]
                return (
                  <div
                    key={getKey()}
                    className={
                      current && focussed
                        ? 'menu-item current focussed'
                        : current
                        ? 'menu-item current'
                        : focussed
                        ? 'menu-item focussed'
                        : 'menu-item'
                    }
                  >
                    <div className={`icon ${name}`} />
                    <span className={'menu-item__text'}>{label}</span>
                  </div>
                )
              })}
            </div>
          </StyledDiv>
        )}
        {focussed && <StyledMask />}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ config, focus, user, player }) => ({
  config,
  focus,
  user,
  player
})

const connected = connect(
  mapStateToProps,
  { logoutRequest }
)(Menu)

export default connected
