import styled from 'styled-components'
import { palette } from 'style'

export const StyledDiv = styled.div`
  font-family: 'Gotham Book', sans-serif;
  > div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${palette.orange};
      width: 3.5vw;
      font-size: 1.8vw;
      margin-bottom: 1vh;
      border-radius: 0.5vw;
      :before {
        content: '';
        float: left;
        padding-top: 100%;
      }
      &.focussed {
        background-color: ${palette.white};
        color: ${palette.black};
      }
    }
    &:nth-child(7) {
      > div {
        &:first-child {
          width: 7.3vw;
          :before {
            content: '';
            float: left;
            padding-top: 50%;
          }
        }
      }
    }
    &:nth-child(8) {
      > div {
        width: 7vw;
        :before {
          content: '';
          float: left;
          padding-top: 50%;
        }
      }
    }
    &:nth-child(9) {
      > div {
        width: 10.5vw;
        :before {
          content: '';
          float: left;
          padding-top: 33%;
        }
      }
    }
  }
`
