import styled from 'styled-components'
import { palette } from 'style'

export const StyledContainer = styled.div`
  width: 25vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: 7.5vw;
`

export const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2.5vw;
  border-radius: 2vw;
`

export const StyledKey = styled.div`
  flex: 1 0 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  margin-right: 5%;
  font-weight: bolder;
  height: 6vh;
  color: ${({ focussed }) => (focussed ? palette.white : palette.black)};
  background-color: ${({ focussed }) =>
    focussed ? palette.orange : palette.white};
  border: 1px solid #000;
  border-radius: 1vw;
  &:nth-child(3n) {
    margin-right: 0;
  }
`
