import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import keydown from 'react-keydown'
import { usedKey } from 'App/focus/actions'
import Controls from './Controls'
import { StyledDiv } from './Player.style'

class PlayerControls extends Component {
  state = {
    showControls: true
  }

  render() {
    const { focus, player } = this.props
    return (
      !!player && (
        <StyledDiv>
          <Controls {...{ focus, player }} />
        </StyledDiv>
      )
    )
  }
}

const mapStateToProps = ({ focus, player }) => ({ focus, player })
const connected = connect(
  mapStateToProps,
  { usedKey }
)(PlayerControls)
export default keydown(connected)
