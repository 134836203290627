import { baseRequest, getAuthHeaders } from 'utils'

export const REQUEST_SHOW = 'REQUEST_SHOW'
export const RECEIVE_SHOW = 'RECEIVE_SHOW'
export const ERROR_SHOW = 'ERROR_SHOW'

const getEndpoint = (id, content_type) => {
  let endpoint
  let appendLang = ''
  const { REACT_APP_BUILD_TYPE } = process.env
  if (REACT_APP_BUILD_TYPE === 'vodafonero') {
    appendLang = '&device_lang=RO' // Append device_lang=RO for vodafonero build
  }
  switch (content_type) {
    case 'VIDEOS':
      endpoint = `videos/video_list?property=${id}${appendLang}`
      break
    case 'MUSICS':
      endpoint = `musics/music_list?resolution=3&property=${id}${appendLang}`
      break
    case 'BOOKS':
      endpoint = `books/collection_content?content_type=VIDEO_BOOK&resolution=3&property=${id}${appendLang}`
      break
    default:
      endpoint = `videos/video_list?property=${id}`
  }
  return endpoint
}

export function fetchShow(id, content_type, token) {
  return dispatch => {
    dispatch(requestShow())
    return !!token
      ? baseRequest
          .get(getEndpoint(id, content_type), getAuthHeaders(token))
          .then(response => dispatch(receiveShow(response.data.items)))
          .catch(err => console.log(error))
      : baseRequest
          .get(getEndpoint(id, content_type))
          .then(response => dispatch(receiveShow(response.data.items)))
          .catch(err => console.log(error))
  }
}
const requestShow = () => ({ type: REQUEST_SHOW })
const receiveShow = payload => ({ type: RECEIVE_SHOW, payload })
const error = payload => ({ type: ERROR_SHOW, payload })
