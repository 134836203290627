import React from 'react'
import Lock from 'components/Lock'
import { StyledDiv, StyledTile } from './LastWatchedTile.style'

const isFocussed = ({ xFocus, xFocusId, yFocus, yFocusId, itemIndex }) =>
  xFocus === xFocusId && yFocus === yFocusId && itemIndex === 0

const findObjectById = (hid, shows, idType) =>
  shows.find(video => video[idType] === hid)

const Tile = props => {
  const {
    item: { series_hid },
    showLock,
    shows
  } = props
  const showItem = findObjectById(series_hid, shows, 'hid')
  const { content_type, leaf_url, title } = !!showItem ? showItem : false
  const focussed = isFocussed(props)
  let className = isFocussed(props)
    ? content_type === 'MUSICS'
      ? 'focussed tilted'
      : content_type === 'VIDEOS'
      ? 'focussed circle'
      : 'focussed'
    : null

  return (
    <StyledDiv>
      <div {...{ className }} />
      {showLock && <Lock />}
      <img
        src={`${leaf_url.replace('http://', 'https://')}=s200`}
        alt={title}
      />
    </StyledDiv>
  )
}

export default Tile
