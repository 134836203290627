import styled from 'styled-components'
import { palette } from 'style'

export const StyledDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 67.5vw;
  height: 100vh;

  h2 {
    padding-top: 8vh;
    margin-bottom: 12vh !important;
  }

  .input-container {
    box-sizing: border-box;
    height: 7vw;
    position: relative;
    margin-bottom: 2vh;
  }

  .label {
    font-size: 1.6vw;
    opacity: 0.6;
  }

  .input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: ${palette.black};
    background-color: ${palette.white};
    border: 4px solid transparent;
    border-radius: 0.4vw;
    margin: 0 auto;
    padding: 0 2vw;
    font-size: 1.6vw;
    width: 30vw;
    height: 8vh;
    &.focussed {
      border: 4px solid ${palette.orange};
    }
    &.error {
      border: 0.52vw solid ${palette.errorRed};
    }
  }

  .results {
    width: 100%;
    p {
      width: 100%;
      font-size: 2.5vw;
      padding: 2.5vh 4vw;
      font-family: 'Gotham';
    }
    &.focussed {
      p {
        &.focussed {
          background-color: ${palette.orange};
        }
      }
    }
  }
`
