import React, { Component } from 'react'
import { connect } from 'react-redux'
import { history } from 'store'
import { notEqual } from 'utils'
import { StyledSection } from './Shows.style'

const isFocussed = () => true

class Shows extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus } = nextProps
      const {
        focus: { moveLeft, pressBack },
        handleMoveXFocus
      } = this.props
      notEqual(focus.pressBack, pressBack) && history.goBack()
      notEqual(focus.moveLeft, moveLeft) && handleMoveXFocus(-1)
    }
  }

  render() {
    return (
      <StyledSection>
        <h2>{'Shows'}</h2>
      </StyledSection>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  {}
)(Shows)
export default connected
