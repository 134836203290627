import { connect } from 'react-redux'
import { logoutRequest } from 'App/auth/actions'

const Logout = ({ logoutRequest }) => {
  logoutRequest()
  return null
}

const connected = connect(
  null,
  { logoutRequest }
)(Logout)

export default connected
