import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notEqual } from 'utils'
import { history } from 'store'
import { moveXFocus } from 'App/focus/actions'
import {
  StyledSection,
  StyledDiv,
  StyledContainer,
  StyledScrollers,
  StyledScrollButtons
} from './ScrollableContainer.style'

const isFocussed = ({ focus: { xFocus }, xFocusId, yFocusId, yFocus }) =>
  xFocus === xFocusId && yFocus <= yFocusId

class ScrollableContainer extends Component {
  state = {
    scrollComplete: false,
    contentScroll: 0,
    scrolledDown: 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isFocussed(this.props)) {
      const { focus, yFocus } = nextProps
      const {
        focus: { pressEnter }
      } = this.props
      const { translateY } = this
      console.log(yFocus)
      notEqual(pressEnter, focus.pressEnter) && yFocus === 0 && translateY(-1)
      notEqual(pressEnter, focus.pressEnter) && yFocus === 1 && translateY(1)
    }
  }

  translateY = step => {
    if (this.containerMask && this.containerEl) {
      const { scrolledDown, scrollComplete } = this.state
      let scroll
      if (step < 0) {
        scroll = scrolledDown > 0 ? scrolledDown + step : 0
      } else {
        scroll = !scrollComplete ? scrolledDown + step : scrolledDown
      }
      const nextPosition = scroll * (this.containerEl.clientHeight - 50) // height to jump when scrolling
      const max =
        this.containerEl.clientHeight - this.containerMask.clientHeight
      const contentScroll =
        -nextPosition > max ? Math.min(0, -nextPosition) : Math.min(0, max)
      const _scrollComplete = -nextPosition < max
      this.setState({
        contentScroll,
        scrollComplete: _scrollComplete,
        scrolledDown: scroll
      })
    }
  }

  getTranslate = scroll => ({ transform: 'translate(0,' + scroll + 'px)' })

  render() {
    const { contentScroll, barScroll } = this.state
    const { getTranslate } = this
    const { type, yFocus } = this.props
    const focussed = isFocussed(this.props)
    return (
      <StyledDiv ref={div => (this.containerEl = div)}>
        <StyledContainer
          ref={div => (this.containerMask = div)}
          style={getTranslate(contentScroll)}
        >
          {this.props.children}
        </StyledContainer>
        <StyledScrollers>
          <StyledScrollButtons focussed={focussed && yFocus === 0} />
          <StyledScrollButtons focussed={focussed && yFocus === 1} />
        </StyledScrollers>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ focus }) => ({ focus })
const connected = connect(
  mapStateToProps,
  { moveXFocus }
)(ScrollableContainer)
export default connected
