import {
  MOVE_UP,
  MOVE_DOWN,
  MOVE_RIGHT,
  MOVE_LEFT,
  MOVE_X_FOCUS,
  MOVE_Y_FOCUS,
  RESET_X_FOCUS,
  RESET_Y_FOCUS,
  PRESS_ENTER,
  PRESS_BACK,
  PLAY_PAUSE,
  REWIND,
  FAST_FORWARD,
  SCROLL_HEIGHT,
  IMAGE_WIDTH
} from './actions'

const initialState = {
  xFocus: 0,
  yFocus: 0,
  moveUp: 0,
  moveDown: 0,
  moveRight: 0,
  moveLeft: 0,
  pressEnter: 0,
  pressBack: 0,
  playPause: 0,
  fastForward: 0,
  rewind: 0,
  scrollHeight: false,
  imageWidth: false
}

const reducer = function focusReducer(state = initialState, action) {
  switch (action.type) {
    case MOVE_UP:
      return {
        ...state,
        moveUp: state.moveUp++
      }
    case MOVE_DOWN:
      return {
        ...state,
        moveDown: state.moveDown++
      }
    case MOVE_RIGHT:
      return {
        ...state,
        moveRight: state.moveRight++
      }
    case MOVE_LEFT:
      return {
        ...state,
        moveLeft: state.moveLeft++
      }
    case PRESS_ENTER:
      return {
        ...state,
        pressEnter: state.pressEnter++
      }
    case PRESS_BACK:
      return {
        ...state,
        pressBack: state.pressBack++
      }
    case PLAY_PAUSE:
      return {
        ...state,
        playPause: state.playPause++
      }
    case REWIND:
      return {
        ...state,
        rewind: state.rewind++
      }
    case FAST_FORWARD:
      return {
        ...state,
        fastForward: state.fastForward++
      }
    case MOVE_X_FOCUS:
      const xFocus =
        state.xFocus === 0 && action.payload < 0
          ? 0
          : state.xFocus + action.payload
      return {
        ...state,
        xFocus
      }
    case MOVE_Y_FOCUS:
      const yFocus =
        state.yFocus === 0 && action.payload < 0
          ? 0
          : state.yFocus + action.payload
      return {
        ...state,
        yFocus
      }
    case RESET_X_FOCUS:
      const xFocusReset = Number.isInteger(action.payload) ? action.payload : 0
      return {
        ...state,
        xFocus: xFocusReset
      }
    case RESET_Y_FOCUS:
      const yFocusReset = Number.isInteger(action.payload) ? action.payload : 0
      return {
        ...state,
        yFocus: yFocusReset
      }
    case SCROLL_HEIGHT:
      return {
        ...state,
        scrollHeight: action.payload
      }
    case IMAGE_WIDTH:
      return {
        ...state,
        imageWidth: action.payload
      }
    default:
      return state
  }
}

export default reducer
